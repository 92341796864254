import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Div from '@jumbo/shared/Div';
import AddQuestion from './AddQuestion';
import questions from 'modules/questions';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import Question from './Question';
import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder';

const Questions = props => {
  const Swal = useSwalWrapper();
  const [expanded, setExpanded] = React.useState(false);
  // const [openQuestionDialog, setOpenQuestionDialog] = React.useState(false);
  const [isLoading, setIsloading] = React.useState(true);
  const [questionData, setQuestionData] = React.useState([]);
  const [questionDialog, setQuestionDialog] = React.useState({
    open: false,
  });

  React.useEffect(() => {
    getAllQuestionsByLesson(props.id);
  }, [props.id]);

  const handleChange = panel => (event, isExpanded) => {
    console.log(isExpanded, 'is', panel, 'panell');
    setExpanded(isExpanded ? panel : false);
  };

  const setOpenQuestionDialog = data => {
    setQuestionDialog({ open: true, data: data });
    console.log('questn', questionDialog, data);
  };

  const handleCloseDialog = () => {
    setQuestionDialog({ open: false });
  };

  const getAllQuestionsByLesson = id => {
    questions.getAllQuestionsByLesson(id, response => {
      if (response.status === 'success') {
        setIsloading(false);
        setQuestionData(response.data);
        console.log(response.data,"QuestionData")
      } else {
        setQuestionData([]);
        setIsloading(false);
      }
    });
  };

  return (
    <Div>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant={'h2'}>Questions</Typography>
        <Button variant="contained" onClick={() => setOpenQuestionDialog(true)}>
          Add Question
        </Button>
      </Box>
      {isLoading ? (
        <div className="loader">
          <svg className="circular-loader" viewBox="25 25 50 50">
            <circle className="loader-path" cx="50" cy="50" r="20" />
          </svg>
        </div>
      ) : questionData?.length <= 0 && !isLoading ? (
        <JumboListNoDataPlaceholder>
          <NoDataPlaceholder />
        </JumboListNoDataPlaceholder>
      ) : (
        questionData.map((question, index) => (
          <Question
            key={index}
            index={index}
            question={question}
            lessonId={props.id}
            openQuestionDialog={setOpenQuestionDialog}
            getAllQuestionsByLesson={getAllQuestionsByLesson}
            setQuestionData={setQuestionData}
          />
        ))
      )}

      {questionDialog.open ? (
        <AddQuestion
          open={questionDialog.open}
          lessonId={props.id}
          data={questionDialog.data}
          close={handleCloseDialog}
          getAllQuestionsByLesson={getAllQuestionsByLesson}
          setQuestionData={setQuestionData}
        />
      ) : null}
    </Div>
  );
};

export default Questions;
