import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Box,
	Typography,
	Grid,
} from '@mui/material';
import Project from './Project';
import project from 'app/mmEsoft/module/project';
import Swal from 'sweetalert2';
const EditProject = ({
	user,
	candidate,
	getProject,
	setOpen,
	open,
	setGetProject,
	selectedProject,
	getAllUSerProjects,
}) => {
	const [projectInput, setProjectInput] = useState({
		projectName: selectedProject.projectName,
		projectLink: selectedProject.projectLink,
		companyName: selectedProject.companyName,
		startDate: selectedProject.startDate,
		endDate: selectedProject.endDate,
		description: selectedProject.description,
	});

	const handleSubmit = e => {
		e.preventDefault();
		project.editProjectData(selectedProject.id, projectInput, response => {
			console.log(response);
			if (response.status === 'success') {
				setProjectInput(response?.data);
				getAllUSerProjects(user.id);
				Swal.fire({
					icon: 'success',
					title: 'Project has been updated successfully',
				});
			} else {
				console.log('error in project');
				Swal.fire({
					icon: 'error',
					title: 'There was an error while updating the Project',
				});
			}
		});
		setOpen(false);
	};

	const handleChange = event => {
		setProjectInput({
			...projectInput,
			[event.target.id]: event.target.value,
		});
	};

	return (
		<div>
			<Dialog
				open={open}
				onClose={() => setOpen(false)}
			>
				<DialogTitle>Edit Project</DialogTitle>
				<form onSubmit={handleSubmit}>
					<DialogContent>
						<TextField
							autoFocus
							margin='dense'
							id='projectName'
							label='Project title'
							onChange={handleChange}
							type='text'
							fullWidth
							value={projectInput.projectName}
						/>
						<TextField
							autoFocus
							margin='dense'
							id='projectLink'
							onChange={handleChange}
							label='Project link'
							type='text'
							fullWidth
							value={projectInput.projectLink}
						/>
						<TextField
							autoFocus
							margin='dense'
							id='companyName'
							onChange={handleChange}
							label='Company Name'
							type='text'
							fullWidth
							value={projectInput.companyName}
						/>
						<Grid
							container
							spacing={2}
						>
							<Grid
								item
								md={6}
							>
								<Typography>Start Date</Typography>
								<TextField
									margin='dense'
									id='startDate'
									type='date'
									fullWidth
									onChange={handleChange}
									value={projectInput.startDate}
								/>
							</Grid>
							<Grid
								item
								md={6}
							>
								<Typography>End Date</Typography>
								<TextField
									margin='dense'
									id='endDate'
									type='date'
									fullWidth
									onChange={handleChange}
									value={projectInput.endDate}
								/>
							</Grid>
						</Grid>
						<TextField
							autoFocus
							margin='dense'
							id='description'
							label='Project Details'
							multiline
							rows={4}
							onChange={handleChange}
							fullWidth
							value={projectInput.description}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							variant='outlined'
							onClick={() => setOpen(false)}
						>
							Cancel
						</Button>
						<Button
							variant='outlined'
							type='submit'
						>
							Update
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</div>
	);
};

export default EditProject;
