import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Div from '@jumbo/shared/Div';
import LessonDetails from './LessonDetails';
import AddLesson from './AddLesson';
import lessons from 'modules/lessons';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import examLessons from 'modules/examLessons';
import AddMultipleLesson from './AddMultipleLesson';
import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder';
import dataAndTimeFormatter from 'modules/dataAndTimeFormatter';
import { useTranslation } from 'react-i18next';
const Lessons = props => {
  const Swal = useSwalWrapper();
  const [expanded, setExpanded] = React.useState(false);
  const [openLessonDialog, setOpenLessonDialog] = React.useState(false);
  const [openAddMultipleDialog, setOpenAddMultipleDialog] =
    React.useState(false);
  const [lessonData, setLessonData] = React.useState([]);
  const [isLoading, setIsloading] = React.useState(true);
  const { searchReslts } = useJumboAuth();
  const { searchTerm } = useJumboAuth();
  const { t } = useTranslation();
  React.useEffect(() => {
    if (props.id !== undefined) {
      getAllExamLessonsByExam(props.id);
    } else {
      getAllLessons();
    }
  }, []);
  React.useEffect(() => {
    if (searchReslts.length > 0) {
      setLessonData(searchReslts);
    } else if (searchTerm) {
      // If searchReslts is empty and searchTerm is provided, show "No results found"
      setLessonData([]);
    } else {
      getAllLessons();
    }
  }, [searchReslts]);

  const handleChange = panel => (event, isExpanded) => {
    console.log(isExpanded, 'is', panel, 'panell');
    setExpanded(isExpanded ? panel : false);
  };
  const handleMultipleDialogClose = () => {
    setOpenAddMultipleDialog(false);
  };
  const handleCloseDialog = () => {
    setOpenLessonDialog(false);
  };
  const handleOpenAddLessonDialog = () => {
    setOpenLessonDialog(true);
  };
  const addLesson = data => {
    lessons.addLesson(data, response => {
      if (response.status === 'success') {
        if (props.id !== undefined) {
          getAllLessonsByExam(props.id);
        } else getAllLessons();
        Swal.fire({
          icon: 'success',
          title: 'Lesson has been added successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while adding lesson.',
        });
      }
    });
  };
  const getAllLessons = () => {
    lessons.getAllLessons(response => {
      if (response.status === 'success') {
        setIsloading(false);
        setLessonData(response.data);
      } else {
        setLessonData([]);
        setIsloading(false);
      }
    });
  };
  const getAllLessonsByExam = id => {
    lessons.getAllLessonsByExam(id, response => {
      if (response.status === 'success') {
        setLessonData(response.data);
        setIsloading(false);
      } else {
        setIsloading(false);
        setLessonData([]);
      }
    });
  };
  const getAllExamLessonsByExam = id => {
    examLessons.getLessonsByExamId(id, response => {
      if (response.status === 'success') {
        setLessonData(response.data);
        setIsloading(false);
      } else {
        setIsloading(false);
        setLessonData([]);
      }
    });
  };

  return (
    <Div>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant={'h2'}> {t('pages.title.Lessons')}</Typography>
        {props.id ? (
          <Button
            variant="contained"
            onClick={() => setOpenAddMultipleDialog(true)}
            style={{ width: '200px', height: '40px' }}
          >
            Add Lessons
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={handleOpenAddLessonDialog}
            style={{ width: '200px', height: '40px' }}
          >
            {/* Add Lesson */}
            {t('pages.title.addLesson')}
          </Button>
        )}
      </Box>
      {isLoading ? (
        <div className="loader" style={{ marginTop: '10vw' }}>
          <svg className="circular-loader" viewBox="25 25 50 50">
            <circle className="loader-path" cx="50" cy="50" r="20" />
          </svg>
        </div>
      ) : lessonData?.length <= 0 && !isLoading ? (
        <JumboListNoDataPlaceholder>
          <NoDataPlaceholder />
        </JumboListNoDataPlaceholder>
      ) : (
        <>
          {lessonData.map((lesson, index) => (
            <Card sx={{ mb: 1 }}>
              <Accordion
                expanded={expanded === 'panel' + index}
                onChange={handleChange('panel' + index)}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === 'panel' + index ? <RemoveIcon /> : <AddIcon />
                  }
                  // expandIcon={<ExpandMoreIcon />}
                  // aria-controls="panel1bh-content"
                  // id="panel1bh-header"
                >
                  <Div
                    sx={{
                      width: { xs: 'auto', lg: '26%' },
                      flexShrink: 0,
                      px: 1,
                      flex: { xs: '1', lg: '0 1 auto' },
                    }}
                  >
                    <Typography>
                      {lesson.title ? lesson.title : lesson.title1}
                    </Typography>
                  </Div>
                  <Div
                    sx={{
                      width: { xs: 'auto', lg: '76%' },
                      flexShrink: 0,
                      px: 1,
                      flex: { xs: '1', lg: '0 1 auto' },
                    }}
                  >
                    <Typography
                      sx={{ color: 'text.secondary', textAlign: 'justify' }}
                    >
                      {lesson.description
                        ? lesson.description
                        : lesson.description1}
                    </Typography>
                  </Div>
                  <Div
                    sx={{
                      width: { xs: 'auto', lg: '26%' },
                      flexShrink: 0,
                      px: 1,
                      flex: { xs: '1', lg: '0 1 auto' },
                    }}
                  >
                    <Typography>
                      {dataAndTimeFormatter.formatDateToCustomFormat(
                        lesson?.createdAt
                      )}
                    </Typography>
                  </Div>
                  <Div
                    sx={{
                      width: { xs: 'auto', lg: '26%' },
                      flexShrink: 0,
                      px: 1,
                      flex: { xs: '1', lg: '0 1 auto' },
                    }}
                  >
                    <Typography>
                      {dataAndTimeFormatter.formatDateToCustomFormat(
                        lesson?.updatedAt
                      )}{' '}
                    </Typography>
                  </Div>
                </AccordionSummary>
                <AccordionDetails>
                  <LessonDetails
                    lesson={lesson}
                    setExpanded={setExpanded}
                    examId={props.id}
                    getAllLessons={getAllLessons}
                    getAllLessonsByExam={getAllLessonsByExam}
                    getAllExamLessonsByExam={getAllExamLessonsByExam}
                  />
                </AccordionDetails>
              </Accordion>
            </Card>
          ))}
        </>
      )}

      {openAddMultipleDialog && (
        <AddMultipleLesson
          close={handleMultipleDialogClose}
          examId={props?.id}
          open={openAddMultipleDialog}
          getAllExamLessonsByExam={getAllExamLessonsByExam}
        />
      )}

      {openLessonDialog ? (
        <AddLesson
          open={openLessonDialog}
          close={handleCloseDialog}
          addLesson={addLesson}
          id={props.id}
        />
      ) : null}
    </Div>
  );
};

export default Lessons;
