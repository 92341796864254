import {
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import VisitCount from 'app/shared/metrics/VisitsStatistics/VisitCount';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import campaigns from 'modules/campaigns';

import users from 'modules/users';
import phishingUsers from 'modules/phishingUsers';
const Reports = () => {
  const [count, setCount] = React.useState([]);
  const { t } = useTranslation();

  const getReportCount = () => {
    campaigns.getReportCounts(response => {
      if (response.status === 'success') {
        setCount(response.data);
      } else {
        setCount([]);
      }
    });
  };
  React.useEffect(() => {
    getReportCount();
  }, []);

  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAllUsersJoinedCampaigns();
    getAllUsersJoinedPhishing();
    getAllPassedUsers();
    getAllFailedUsers();
    getAllClickedUsers();
    getAllUsersByVideoStatus();
    getAllUsersByVideoStatusAndQuizStatus();
    getAllUsersWithQuizScore();
  }, []);

  const getAllUsersJoinedCampaigns = () => {
    phishingUsers.getAllUsersJoinedCampaigns(response => {
      if (response.status === 'success') {
        setUserList(response.data);
        setIsLoading(false);
      } else {
        setUserList([]);
        setIsLoading(false);
      }
    });
  };
  const [phishing, setPhishingList] = useState([]);
  const getAllUsersJoinedPhishing = () => {
    phishingUsers.getAllUsersJoinedPhishing(response => {
      if (response.status === 'success') {
        setPhishingList(response.data);
        setIsLoading(false);
      } else {
        setPhishingList([]);
        setIsLoading(false);
      }
    });
  };

  const [passedUsers, setPassedUserList] = useState([]);
  const getAllPassedUsers = () => {
    phishingUsers.getAllPassedUsers(response => {
      if (response.status === 'success') {
        setPassedUserList(response.data);
        setIsLoading(false);
      } else {
        setPassedUserList([]);
        setIsLoading(false);
      }
    });
  };

  const [failedUsers, setFailedUserList] = useState([]);
  const getAllFailedUsers = () => {
    phishingUsers.getAllFailedUsers(response => {
      if (response.status === 'success') {
        setFailedUserList(response.data);
        setIsLoading(false);
      } else {
        setFailedUserList([]);
        setIsLoading(false);
      }
    });
  };

  const [clickedUsers, setClickedUserList] = useState([]);
  const getAllClickedUsers = () => {
    phishingUsers.getAllClickedUsers(response => {
      if (response.status === 'success') {
        setClickedUserList(response.data);
        setIsLoading(false);
      } else {
        setClickedUserList([]);
        setIsLoading(false);
      }
    });
  };
  const [watchedUsers, setWatchedUserList] = useState([]);
  const getAllUsersByVideoStatus = () => {
    phishingUsers.getAllUsersByVideoStatus(response => {
      if (response.status === 'success') {
        setWatchedUserList(response.data);
        setIsLoading(false);
      } else {
        setWatchedUserList([]);
        setIsLoading(false);
      }
    });
  };
  const [watchedLesson, setWatchedLesson] = useState([]);
  const getAllUsersByVideoStatusAndQuizStatus = () => {
    phishingUsers.getAllUsersByVideoStatusAndQuizStatus(response => {
      if (response.status === 'success') {
        setWatchedLesson(response.data);
        setIsLoading(false);
      } else {
        setWatchedLesson([]);
        setIsLoading(false);
      }
    });
  };
  const [score, setScore] = useState([]);
  const getAllUsersWithQuizScore = () => {
    phishingUsers.getAllUsersWithQuizScore(response => {
      if (response.status === 'success') {
        setScore(response.data);
        console.log(response.data, 'kkkkpp');
        setIsLoading(false);
      } else {
        setScore([]);
        setIsLoading(false);
      }
    });
  };
  return (
    <Grid container spacing={3.75}>
      <Grid item xs={12} sm={6} lg={3}>
        <VisitCount
          count={count}
          bgColor="#42A5F5"
          content="All Campaigns present in this Training and Awareness Program"
          cardHeaderTitle={t('pages.title.allCampaigns')}
          usedInClient={true}
          type={'allCounts'}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <VisitCount
          count={count}
          bgColor="#42A5F5"
          content="All Learning Campaigns present in this Training and Awareness Program"
          // cardHeaderTitle={t('pages.title.campaignsCompleted')}
          cardHeaderTitle="All Learning Campaigns"
          usedInClient={true}
          // user={user}
          type={'all'}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <VisitCount
          count={count}
          bgColor="#42A5F5"
          content="All Active Learning Campaigns that are already running"
          cardHeaderTitle="Active Learning Campaigns"
          usedInClient={true}
          type={'active'}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <VisitCount
          count={count}
          bgColor="#42A5F5"
          content="All Active Phishing Campaigns that are already running "
          cardHeaderTitle="Active Phishing Campaigns"
          usedInClient={true}
          type={'phishingactive'}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <TableContainer>
          <Table>
            {isLoading ? (
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Skeleton variant="rectangular" height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rectangular" height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rectangular" height={40} />
                  </TableCell>
                </TableRow>
              </TableHead>
            ) : (
              <TableHead>
                <TableRow
                  sx={{
                    padding: '0px !important',
                    boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                    borderRadius: '12px',
                    backgroundColor: 'rgb(255, 255, 255)',
                    textAlign: 'center',
                  }}
                >
                  {' '}
                  <TableCell colSpan={2}>
                    <b>All Users joined Learning Campaigns</b>
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    padding: '0px !important',
                    boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                    borderRadius: '12px',
                    backgroundColor: 'rgb(255, 255, 255)',
                  }}
                >
                  <TableCell style={{ width: '34%' }}>UserName</TableCell>
                  <TableCell style={{ width: '34%' }}>Email</TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Skeleton
                      variant="rectangular"
                      height={40}
                      style={{ width: '100%' }}
                    />
                  </TableCell>
                </TableRow>
              ) : userList?.result?.length > 0 ? (
                userList.result.map(user => (
                  <TableRow
                    sx={{
                      padding: '2px 2px 2px 10px',
                      boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                      borderRadius: '12px',
                      backgroundColor: 'rgb(255, 255, 255)',
                    }}
                  >
                    <TableCell
                      sx={{
                        padding: '2px 2px 2px 20px !important',
                      }}
                    >
                      <Typography variant={'h6'} mb={0}>
                        {user.user_name}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        padding: '2px 2px 2px 20px !important',
                      }}
                    >
                      <Typography variant={'h6'} mb={0}>
                        {user.email}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography variant="body1">No users found.</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <TableContainer>
          <Table>
            {isLoading ? (
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Skeleton variant="rectangular" height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rectangular" height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rectangular" height={40} />
                  </TableCell>
                </TableRow>
              </TableHead>
            ) : (
              <TableHead>
                <TableRow
                  sx={{
                    padding: '0px !important',
                    boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                    borderRadius: '12px',
                    backgroundColor: 'rgb(255, 255, 255)',
                    textAlign: 'center',
                  }}
                >
                  {' '}
                  <TableCell colSpan={2}>
                    <b>All Users joined phishing Campaigns</b>
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    padding: '0px !important',
                    boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                    borderRadius: '12px',
                    backgroundColor: 'rgb(255, 255, 255)',
                  }}
                >
                  <TableCell style={{ width: '34%' }}>UserName</TableCell>
                  <TableCell style={{ width: '34%' }}>Email</TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Skeleton
                      variant="rectangular"
                      height={40}
                      style={{ width: '100%' }}
                    />
                  </TableCell>
                </TableRow>
              ) : phishing?.result?.length > 0 ? (
                phishing.result.map(user => (
                  <TableRow
                    sx={{
                      padding: '2px 2px 2px 10px',
                      boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                      borderRadius: '12px',
                      backgroundColor: 'rgb(255, 255, 255)',
                    }}
                  >
                    <TableCell
                      sx={{
                        padding: '2px 2px 2px 20px !important',
                      }}
                    >
                      <Typography variant={'h6'} mb={0}>
                        {user.user_name}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        padding: '2px 2px 2px 20px !important',
                      }}
                    >
                      <Typography variant={'h6'} mb={0}>
                        {user.email}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography variant="body1">No users found.</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12} sm={6} lg={6}>
        <TableContainer>
          <Table>
            {isLoading ? (
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Skeleton variant="rectangular" height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rectangular" height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rectangular" height={40} />
                  </TableCell>
                </TableRow>
              </TableHead>
            ) : (
              <TableHead>
                <TableRow
                  sx={{
                    padding: '0px !important',
                    boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                    borderRadius: '12px',
                    backgroundColor: 'rgb(255, 255, 255)',
                    textAlign: 'center',
                  }}
                >
                  {' '}
                  <TableCell colSpan={2}>
                    <b>All Passed Users</b>
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    padding: '0px !important',
                    boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                    borderRadius: '12px',
                    backgroundColor: 'rgb(255, 255, 255)',
                  }}
                >
                  <TableCell style={{ width: '34%' }}>UserName</TableCell>
                  <TableCell style={{ width: '34%' }}>Email</TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Skeleton
                      variant="rectangular"
                      height={40}
                      style={{ width: '100%' }}
                    />
                  </TableCell>
                </TableRow>
              ) : passedUsers?.phishingUsersDifference?.length > 0 ? (
                passedUsers.phishingUsersDifference.map(user => (
                  <TableRow
                    sx={{
                      padding: '2px 2px 2px 10px',
                      boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                      borderRadius: '12px',
                      backgroundColor: 'rgb(255, 255, 255)',
                    }}
                  >
                    <TableCell
                      sx={{
                        padding: '2px 2px 2px 20px !important',
                      }}
                    >
                      <Typography variant={'h6'} mb={0}>
                        {user.userName}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        padding: '2px 2px 2px 20px !important',
                      }}
                    >
                      <Typography variant={'h6'} mb={0}>
                        {user.email}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography variant="body1">No users found.</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <TableContainer>
          <Table>
            {isLoading ? (
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Skeleton variant="rectangular" height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rectangular" height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rectangular" height={40} />
                  </TableCell>
                </TableRow>
              </TableHead>
            ) : (
              <TableHead>
                <TableRow
                  sx={{
                    padding: '0px !important',
                    boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                    borderRadius: '12px',
                    backgroundColor: 'rgb(255, 255, 255)',
                    textAlign: 'center',
                  }}
                >
                  {' '}
                  <TableCell colSpan={2}>
                    <b>All Failed Users </b>
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    padding: '0px !important',
                    boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                    borderRadius: '12px',
                    backgroundColor: 'rgb(255, 255, 255)',
                  }}
                >
                  <TableCell style={{ width: '34%' }}>UserName</TableCell>
                  <TableCell style={{ width: '34%' }}>Email</TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Skeleton
                      variant="rectangular"
                      height={40}
                      style={{ width: '100%' }}
                    />
                  </TableCell>
                </TableRow>
              ) : failedUsers?.phishingUsers?.length > 0 ? (
                failedUsers.phishingUsers.map(user => (
                  <TableRow
                    sx={{
                      padding: '2px 2px 2px 10px',
                      boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                      borderRadius: '12px',
                      backgroundColor: 'rgb(255, 255, 255)',
                    }}
                  >
                    <TableCell
                      sx={{
                        padding: '2px 2px 2px 20px !important',
                      }}
                    >
                      <Typography variant={'h6'} mb={0}>
                        {user.userName}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        padding: '2px 2px 2px 20px !important',
                      }}
                    >
                      <Typography variant={'h6'} mb={0}>
                        {user.email}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography variant="body1">No users found.</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12} sm={6} lg={6}>
        <TableContainer>
          <Table>
            {isLoading ? (
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Skeleton variant="rectangular" height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rectangular" height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rectangular" height={40} />
                  </TableCell>
                </TableRow>
              </TableHead>
            ) : (
              <TableHead>
                <TableRow
                  sx={{
                    padding: '0px !important',
                    boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                    borderRadius: '12px',
                    backgroundColor: 'rgb(255, 255, 255)',
                    textAlign: 'center',
                  }}
                >
                  {' '}
                  <TableCell colSpan={2}>
                    <b>Users Clicked On Phishing URL</b>
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    padding: '0px !important',
                    boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                    borderRadius: '12px',
                    backgroundColor: 'rgb(255, 255, 255)',
                  }}
                >
                  <TableCell style={{ width: '34%' }}>UserName</TableCell>
                  <TableCell style={{ width: '34%' }}>Email</TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Skeleton
                      variant="rectangular"
                      height={40}
                      style={{ width: '100%' }}
                    />
                  </TableCell>
                </TableRow>
              ) : clickedUsers?.result?.length > 0 ? (
                clickedUsers.result.map(user => (
                  <TableRow
                    sx={{
                      padding: '2px 2px 2px 10px',
                      boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                      borderRadius: '12px',
                      backgroundColor: 'rgb(255, 255, 255)',
                    }}
                  >
                    <TableCell
                      sx={{
                        padding: '2px 2px 2px 20px !important',
                      }}
                    >
                      <Typography variant={'h6'} mb={0}>
                        {user.user_name}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        padding: '2px 2px 2px 20px !important',
                      }}
                    >
                      <Typography variant={'h6'} mb={0}>
                        {user.email}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography variant="body1">No users found.</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12} sm={6} lg={6}>
        <TableContainer>
          <Table>
            {isLoading ? (
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Skeleton variant="rectangular" height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rectangular" height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rectangular" height={40} />
                  </TableCell>
                </TableRow>
              </TableHead>
            ) : (
              <TableHead>
                <TableRow
                  sx={{
                    padding: '0px !important',
                    boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                    borderRadius: '12px',
                    backgroundColor: 'rgb(255, 255, 255)',
                    textAlign: 'center',
                  }}
                >
                  {' '}
                  <TableCell colSpan={2}>
                    <b>All Users That Watched The Lesson Till The End</b>
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    padding: '0px !important',
                    boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                    borderRadius: '12px',
                    backgroundColor: 'rgb(255, 255, 255)',
                  }}
                >
                  <TableCell style={{ width: '34%' }}>UserName</TableCell>
                  <TableCell style={{ width: '34%' }}>Email</TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Skeleton
                      variant="rectangular"
                      height={40}
                      style={{ width: '100%' }}
                    />
                  </TableCell>
                </TableRow>
              ) : watchedUsers?.users?.length > 0 ? (
                watchedUsers.users.map(user => (
                  <TableRow
                    sx={{
                      padding: '2px 2px 2px 10px',
                      boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                      borderRadius: '12px',
                      backgroundColor: 'rgb(255, 255, 255)',
                    }}
                  >
                    <TableCell
                      sx={{
                        padding: '2px 2px 2px 20px !important',
                      }}
                    >
                      <Typography variant={'h6'} mb={0}>
                        {user.userName}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        padding: '2px 2px 2px 20px !important',
                      }}
                    >
                      <Typography variant={'h6'} mb={0}>
                        {user.email}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography variant="body1">No users found.</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12} sm={6} lg={6}>
        <TableContainer>
          <Table>
            {isLoading ? (
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Skeleton variant="rectangular" height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rectangular" height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rectangular" height={40} />
                  </TableCell>
                </TableRow>
              </TableHead>
            ) : (
              <TableHead>
                <TableRow
                  sx={{
                    padding: '0px !important',
                    boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                    borderRadius: '12px',
                    backgroundColor: 'rgb(255, 255, 255)',
                    textAlign: 'center',
                  }}
                >
                  {' '}
                  <TableCell colSpan={2}>
                    <b>
                      All Users That Take The Quiz After Watching The Lesson
                    </b>
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    padding: '0px !important',
                    boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                    borderRadius: '12px',
                    backgroundColor: 'rgb(255, 255, 255)',
                  }}
                >
                  <TableCell style={{ width: '34%' }}>UserName</TableCell>
                  <TableCell style={{ width: '34%' }}>Email</TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Skeleton
                      variant="rectangular"
                      height={40}
                      style={{ width: '100%' }}
                    />
                  </TableCell>
                </TableRow>
              ) : watchedLesson?.users?.length > 0 ? (
                watchedLesson.users.map(user => (
                  <TableRow
                    sx={{
                      padding: '2px 2px 2px 10px',
                      boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                      borderRadius: '12px',
                      backgroundColor: 'rgb(255, 255, 255)',
                    }}
                  >
                    <TableCell
                      sx={{
                        padding: '2px 2px 2px 20px !important',
                      }}
                    >
                      <Typography variant={'h6'} mb={0}>
                        {user.userName}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        padding: '2px 2px 2px 20px !important',
                      }}
                    >
                      <Typography variant={'h6'} mb={0}>
                        {user.email}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography variant="body1">No users found.</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12} sm={6} lg={6}>
        <TableContainer>
          <Table>
            {isLoading ? (
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Skeleton variant="rectangular" height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rectangular" height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rectangular" height={40} />
                  </TableCell>
                </TableRow>
              </TableHead>
            ) : (
              <TableHead>
                <TableRow
                  sx={{
                    padding: '0px !important',
                    boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                    borderRadius: '12px',
                    backgroundColor: 'rgb(255, 255, 255)',
                    textAlign: 'center',
                  }}
                >
                  {' '}
                  <TableCell colSpan={3}>
                    <b>Users Score After Answer The Question</b>
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    padding: '0px !important',
                    boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                    borderRadius: '12px',
                    backgroundColor: 'rgb(255, 255, 255)',
                  }}
                >
                  <TableCell style={{ width: '34%' }}>UserName</TableCell>
                  <TableCell style={{ width: '34%' }}>Email</TableCell>
                  <TableCell style={{ width: '34%' }}>QuizScore</TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Skeleton
                      variant="rectangular"
                      height={40}
                      style={{ width: '100%' }}
                    />
                  </TableCell>
                </TableRow>
              ) : score?.result?.length > 0 ? (
                score.result.map(user => (
                  <TableRow
                    sx={{
                      padding: '2px 2px 2px 10px',
                      boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                      borderRadius: '12px',
                      backgroundColor: 'rgb(255, 255, 255)',
                    }}
                  >
                    <TableCell
                      sx={{
                        padding: '2px 2px 2px 20px !important',
                      }}
                    >
                      <Typography variant={'h6'} mb={0}>
                        {user.user_name}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        padding: '2px 2px 2px 20px !important',
                      }}
                    >
                      <Typography variant={'h6'} mb={0}>
                        {user.email}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        padding: '2px 2px 2px 20px !important',
                      }}
                    >
                      <Typography variant={'h6'} mb={0}>
                        {user.quiz_score}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography variant="body1">No users found.</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default Reports;
