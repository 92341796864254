import {
  Stack,
  Typography,
  Menu,
  MenuItem,
  Button,
  Grid,
  Dialog,
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Select,
  Autocomplete,
} from '@mui/material';
import { useState, useEffect } from 'react';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import ContactModule from 'app/mmEsoft/module/ContactModule';
import { useGetAllLabels } from './filterContacts/hooks/useGetAllLabels';
import { useGetAllCollages } from './filterContacts/hooks/useGetAllCollages';
import { useGetAllCourseRtln } from './filterContacts/hooks/useGetAllCourseRtln';
function EditContact(props) {
  const Swal = useSwalWrapper();
  const [editFormData, setEditFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    addressId: null,
    leadDate: null,
    labels: '',
    semester: '',
    college: '',
    courseRelation: '',
    remarks: '',
  });
  useEffect(() => {
    setEditFormData({
      ...editFormData,
      name: props.editTableRowData.name,
      email: props.editTableRowData.email,
      phoneNumber: props.editTableRowData.phoneNumber,
      addressId: props.editTableRowData.addressId,
      leadDate: new Date(props.editTableRowData.leadDate).toLocaleDateString(
        'en-CA'
      ),
      labels: props.editTableRowData.labels,
      semester: props.editTableRowData.semester,
      college: props.editTableRowData.college,
      courseRelation: props.editTableRowData.courseRelation,
      remarks: props.editTableRowData.remarks,
    });
  }, []);
  const handleEditFormData = event => {
    let name = event.target.name;
    let value = event.target.value;

    setEditFormData({
      ...editFormData,
      [name]: value,
    });
  };

  const handleUpdateEditData = () => {
    let id = props.editTableRowData.id;
    ContactModule.editContact(id, editFormData, response => {
      if (response.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'Data Updated Successfully.',
        });
        props.getAllContacts();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error Occured While Updating Data!',
        });
      }
    });
    props.handleCloseEditModal(); //for automatic close
    props.handleCloseMenuModal();
  };

  const { labelsList, loader } = useGetAllLabels();
  // GET CONTACTS
  const { collageList, collageLoader } = useGetAllCollages();
  // GET COURSES
  const { courseList, courseLoder } = useGetAllCourseRtln();

  const semesterList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <>
      <Dialog
        open={props.openEdit}
        onClose={props.handleCloseEditModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>Edit Contact</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={2} columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  id="name"
                  label="name"
                  variant="outlined"
                  fullWidth
                  name="name"
                  defaultValue={props?.editTableRowData?.name}
                  onChange={handleEditFormData}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  id="email"
                  label="email"
                  fullWidth
                  variant="outlined"
                  name="email"
                  defaultValue={props?.editTableRowData?.email}
                  onChange={handleEditFormData}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  id="phoneNumber"
                  label="phoneNumber"
                  fullWidth
                  variant="outlined"
                  name="phoneNumber"
                  defaultValue={props?.editTableRowData?.phoneNumber}
                  onChange={handleEditFormData}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="addressId"
                  label="addressId"
                  fullWidth
                  variant="outlined"
                  name="addressId"
                  defaultValue={props?.editTableRowData?.addressId}
                  onChange={handleEditFormData}
                />
              </Grid>
              <Grid
                item
                container
                justifyContent={'space-between'}
                alignItems={'end'}
                spacing={2}
              >
                <Grid item md={6}>
                  <label>Lead Date</label>
                  <TextField
                    fullWidth
                    id="leadDate"
                    type="Date"
                    variant="outlined"
                    name="leadDate"
                    defaultValue={new Date(
                      props.editTableRowData.leadDate
                    ).toLocaleDateString('en-CA')}
                    onChange={handleEditFormData}
                  />
                </Grid>

                <Grid item md={6}>
                  <Autocomplete
                    id="labels"
                    fullWidth
                    freeSolo
                    options={labelsList.map(option => option.code)}
                    name="labels"
                    defaultValue={props.editTableRowData.labels}
                    onChange={(event, value) =>
                      setEditFormData({ ...editFormData, labels: value })
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="filled"
                        label="labels"
                        placeholder="Label"
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  disablePortal
                  id="semester"
                  fullWidth
                  label="Semester"
                  name="semester"
                  defaultValue={props.editTableRowData.semester}
                  options={semesterList}
                  onChange={(event, value) =>
                    setEditFormData({ ...editFormData, semester: value })
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Semester"
                      variant="filled"
                      name="semester"
                      onChange={handleEditFormData}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="College"
                  freeSolo
                  options={collageList.map(option => option.college)}
                  fullWidth
                  name="college"
                  defaultValue={props.editTableRowData.college}
                  onChange={(event, value) =>
                    setEditFormData({ ...editFormData, college: value })
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="College" // Set the label here
                      placeholder="College"
                      onChange={handleEditFormData}
                      name="college"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="courseRelation"
                  freeSolo
                  options={courseList.map(option => option.course_relation)}
                  fullWidth
                  name="courseRelation"
                  defaultValue={editFormData.courseRelation}
                  // onChange={handleFormDataOnChange}
                  onChange={(event, value) =>
                    setEditFormData({ ...editFormData, courseRelation: value })
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="courseRelation"
                      placeholder="courseRelation"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  id="remarks"
                  label="remarks"
                  name="remarks"
                  defaultValue={props?.editTableRowData?.remarks}
                  onChange={handleEditFormData}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              props.handleCloseEditModal();
              props.handleCloseMenuModal();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleUpdateEditData();
            }}
            autoFocus
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default EditContact;
