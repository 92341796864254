import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Typography,
  Grid,
  Stack,
  ButtonGroup,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import UserAssigments from 'app/mmEsoft/module/UserAssigments';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useSelector } from 'react-redux';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinkIcon from '@mui/icons-material/Link';
import { Link } from 'react-router-dom';

function UserAssignmentsDetails() {
  const { batchesList } = useSelector(({ batchesReducer }) => batchesReducer);
  const { user } = useSelector(({ login }) => login);
  const { myBatch } = useSelector(({ batchesReducer }) => batchesReducer);
  const [open, setOpen] = useState(false);
  const [assignmentsDetailsLoader, setAssignmentDetailsLoader] =
    useState(false);
  // Access the parameters from the URL
  const { id } = useParams();
  const Swal = useSwalWrapper();
  const [currentUserAssignment, currentsetUserAssignment] = useState([]);
  const [adminFeedback, setAdminFeedback] = useState([]);
  const [assignmentWithoutFile, setAssignmentWithoutFile] = useState();
  const [fileUpload, setFileUpload] = useState({
    file: '',
  });
  const [assignmentStatus, setAssignmentStatus] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    //TODO send id's in body  this way
    answer: '',
    userId: user?.id,
    assignmentId: id,
    batchId: myBatch,
    answerUrl: '',
  });

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  //Single Assignments  Detail
  const handleUserCurrentAssignments = () => {
    setAssignmentDetailsLoader(true);
    // user.id
    // id
    UserAssigments._getUserAssignmentByBatchIdAndUserId(
      myBatch,
      user.id,
      (response) => {
        if (response.status === 'success') {
          currentsetUserAssignment(response?.data);
          setAssignmentDetailsLoader(false);
        } else {
          console.log('error while getting the Assignments ');
          setAssignmentDetailsLoader(false);
        }
      }
    );
  };

  useEffect(() => {
    handleUserCurrentAssignments();
  }, []);

  const currentAssignment = currentUserAssignment.find(
    (item) => item?.id == id
  );

  //handle post request
  const handleAnswerChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setFormData((formData) => {
      return {
        ...formData,
        [name]: value,
      };
    });
  };
  const handleChangeFile = (e) => {
    //handle file seprately
    const uploadFile = e.target.files[0];
    setFileUpload({
      file: uploadFile,
    });
  };

  const handleUpdateOnSubmitClick = (e) => {
    e.preventDefault();
    let answer = document.getElementById('answer');
    let answerText = document.getElementById('answerText');

    if (formData.answer.trim() === '') {
      answer.style.border = '1px solid red';
      answerText.innerHTML = 'This field is required';
      return;
    } else {
      answer.style.border = '1px solid black';
      answerText.innerHTML = '';
    }

    let formDataIs = new FormData();
    formDataIs.append('file', fileUpload.file); // Access the 'file' property of fileUpload
    formDataIs.append('data', JSON.stringify(formData));
    setAssignmentWithoutFile(formDataIs);

    if (fileUpload.file === '') {
      setOpen(true);
      return;
    }
    UserAssigments.updateAssignmentOfUser(formDataIs, (response) => {
      if (response.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: ' Assignment Data Updated Successfully.',
        });
        // navigate('/user-assignments');
        getUserAssignmentByUserId();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error Occured While Updating Assignment Data!',
        });
      }
    });
  };

  // for date Converting
  const convertToIST = (timestamp) => {
    let utc = new Date(timestamp);
    var dateUTC = new Date(utc); // format should be like '2023-11-20T10:30:00'
    var dateUTC = dateUTC.getTime();
    var dateIST = new Date(dateUTC);
    //date shifting for IST timezone (+5 hours and 30 minutes)
    dateIST.setHours(dateIST.getHours() + 5);
    dateIST.setMinutes(dateIST.getMinutes() + 30);

    let dateTimeIST = new Date(dateIST);

    const year = dateTimeIST.getFullYear();
    const month = String(dateTimeIST.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-based
    const day = String(dateTimeIST.getDate()).padStart(2, '0');
    const hours = String(dateTimeIST.getHours()).padStart(2, '0');
    const minutes = String(dateTimeIST.getMinutes()).padStart(2, '0');
    const seconds = String(dateTimeIST.getSeconds()).padStart(2, '0');

    const formattedString = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedString;
  };

  const getUserAssignmentByUserId = () => {
    UserAssigments.getUserAssignmentByUserId(user.id, id, (response) => {
      if (response.status === 'success') {
        setAdminFeedback(response?.data);
        setAssignmentStatus(response?.data?.isSubmitted);
      } else {
        console.log('error occurred in getting feedback of  Assignment');
      }
    });
  };
  useEffect(() => {
    getUserAssignmentByUserId();
  }, []);
  const downloadAssignmentByAssignmentId = () => {
    UserAssigments.downloadAssignmentByAssignmentId(
      id,
      currentAssignment?.file,
      (response) => {
        if (response.status === 'success') {
          const downloadFile = response.data;
          const url = downloadFile.url;
          const a = document.createElement('a');
          a.href = url;
          a.setAttribute('target', '_blank');
          // a.download="fileName"
          a.click();
          a.remove();
        } else {
          console.log('Error while downloading the Admin Assignment');
        }
      }
    );
  };

  const currentDate = new Date()
    .toLocaleDateString('en-IN', {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .split('/')
    .reverse()
    .join('-');

  const handleAssignmentUpload = () => {
    UserAssigments.updateAssignmentOfUser(assignmentWithoutFile, (response) => {
      if (response.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: ' Assignment Data Updated Successfully.',
        });
        setFormData({
          answer: '',
        });
        getUserAssignmentByUserId();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error Occured While Updating Assignment Data!',
        });
      }
    });

    setOpen(false);
  };

  return (
    <>
      {assignmentsDetailsLoader ? (
        <div className='loader' style={{ marginTop: '20%' }}>
          <svg className='circular-loader' viewBox='25 25 50 50'>
            <circle className='loader-path' cx='50' cy='50' r='20' />
          </svg>
        </div>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='flex-start'
              spacing={2}
            >
              <Box>
                <ButtonGroup aria-label='outlined primary button group'>
                  <Link to='/user-assignments'>
                    <Button
                      startIcon={<KeyboardBackspaceIcon />}
                      style={{ padding: '8px' }}
                    >
                      Assignment
                    </Button>
                  </Link>
                </ButtonGroup>
              </Box>
            </Stack>

            <Box style={{ marginTop: '10px' }}>
              <Typography variant='h1'>{currentAssignment?.title}</Typography>
              <Typography variant='body1'>
                {currentAssignment?.description}
              </Typography>

              <Stack
                direction='row'
                justifyContent=''
                alignItems='flex-start'
                spacing={25}
                style={{ backgroundColor: '#fff', padding: '10px' }}
              >
                <Box>
                  <p>
                    <Typography variant='h5'>Start Date </Typography>
                    <Typography variant='body1'>
                      {currentAssignment?.startDate}
                    </Typography>
                  </p>
                  <p>
                    <Typography variant='h5'>File Type </Typography>
                    <Typography variant='body1'>
                      {currentAssignment?.file}
                    </Typography>
                  </p>
                </Box>

                <Box>
                  <p>
                    <Typography variant='h5'>End Date </Typography>
                    <Typography variant='body1'>
                      {currentAssignment?.endDate}
                    </Typography>
                  </p>
                  <p>
                    <Typography variant='h5'>Maximum Marks</Typography>
                    <Typography variant='body1'>
                      {currentAssignment?.maximumMarks}
                    </Typography>
                  </p>
                </Box>
                <Button
                  variant='outlined'
                  startIcon={<ArrowDownwardIcon />}
                  onClick={downloadAssignmentByAssignmentId}
                >
                  Download
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      )}

      {assignmentStatus ? (
        <Stack>
          {adminFeedback?.feedback ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box style={{ marginTop: '10px' }}>
                  <Typography variant='h1'>Remarks</Typography>
                  <Typography variant='body1'>
                    {adminFeedback?.feedback}
                  </Typography>
                  <Typography variant='h1'>
                    <span style={{ color: '#82b440', marginTop: '5px ' }}>
                      {adminFeedback?.score}
                    </span>{' '}
                    /{currentAssignment?.maximumMarks}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Typography
              textAlign={'center'}
              fontSize={'15px'}
              marginTop={'20px'}
            >
              Your assignment is submitted once and your result will be updated
              soon
            </Typography>
          )}
        </Stack>
      ) : (
        <Grid container spacing={2} mt={5}>
          <Grid item xs={12} style={{ backgroundColor: 'white' }}>
            {currentDate > currentAssignment?.endDate ? (
              <Typography
                varient={'h4'}
                sx={{
                  textAlign: 'center',
                  fontSize: '20px',
                  color: '#757575',
                }}
              >
                Assignment data is outdated.
              </Typography>
            ) : (
              <item>
                <Typography variant='h6' component='h6'>
                  Answer
                </Typography>
                <hr />
                <TextField
                  id='answer'
                  variant='outlined'
                  fullWidth
                  name='answer'
                  value={formData.answer}
                  onChange={handleAnswerChange}
                />
                <Typography
                  variant='h6'
                  id='answerText'
                  color='error'
                ></Typography>

                {/* <Stack
                  direction='column'
                  justifyContent='center'
                  alignItems='center'
                  spacing={2}
                  style={{ marginTop: '10px' }}
                >
                  <Button
                    component='label'
                    variant='contained'
                    id='file'
                    name='file'
                    startIcon={<CloudUploadIcon />}
                  >
                    {fileUpload.file
                      ? `File selected${fileUpload.file.name}`
                      : `Upload file Here `}
                    <VisuallyHiddenInput
                      type='file'
                      accept='txt,pdf,docx,jpeg,jpg,png'
                      onChange={handleChangeFile}
                    />
                  </Button>
                  <Box display={'flex'} alignItems={'center'}>
                    <LinkIcon />
                    <TextField
                      vlaue={formData.answerUrl}
                      onChange={(e) =>
                        setFormData({ ...formData, answerUrl: e.target.value })
                      }
                      placeholder='Enter your assignment url (optional) '
                    />
                  </Box>
                </Stack> */}

                <Grid
                  container
                  justifyContent='center'
                  alignItems='center'
                  spacing={2}
                >
                  <Grid item sx={6} md={6} lg={6}>
                    <Button
                      component='label'
                      variant='outlined'
                      fullWidth
                      id='file'
                      name='file'
                      startIcon={<CloudUploadIcon />}
                    >
                      {fileUpload.file
                        ? `File selected${fileUpload.file.name}`
                        : `Upload file Here `}
                      <VisuallyHiddenInput
                        type='file'
                        accept='txt,pdf,docx,jpeg,jpg,png'
                        onChange={handleChangeFile}
                      />
                    </Button>
                  </Grid>
                  <Grid item sx={6} md={6} lg={6}>
                    <Box
                      display='flex'
                      alignItems='center'
                      border={'1px solid #757575'}
                      borderRadius={'5px'}
                    >
                      <LinkIcon
                        padding={'2px'}
                        sx={{
                          width: '80px',
                          fontSize: '34px',
                          background: '#e0e0e0',
                          borderRadius: '5px 0 0 5px',
                        }}
                      />
                      <input
                        value={formData.answerUrl}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            answerUrl: e.target.value,
                          })
                        }
                        style={{
                          all: 'unset',
                          width: '100%',
                          padding: '2px',
                          border: 'none',
                        }}
                        placeholder='Enter your assignment url (optional) '
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Button
                  variant='contained'
                  style={{ marginTop: '5px' }}
                  onClick={handleUpdateOnSubmitClick}
                >
                  Save
                </Button>
              </item>
            )}
          </Grid>
        </Grid>
      )}
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Do you want to submit assignment without file
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setOpen(false)}>
            NO
          </Button>
          <Button
            variant='contained'
            onClick={() => handleAssignmentUpload()}
            autoFocus
          >
            yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UserAssignmentsDetails;
