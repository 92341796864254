import "./addAssignment.css";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Assignment from "app/mmEsoft/module/Assignment";
import { useEffect } from "react";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { IconButton } from "@mui/material";

function EditAssignment(props) {
  console.log(props,"rop data is ")
  const [Batches, setBatches] = useState("");
  const Swal = useSwalWrapper();
  const [fileUpload, setFileUpload] = useState({ file: "" });

  const [formEditData, setFormEditData] = useState({
    title: "",
    description: "",
    file: "",
    startDate: null,
    endDate: null,
    batchId: null,
    maximumMarks: null,
  });
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  useEffect(() => {
    setFormEditData({
      ...formEditData,
      title: props.editAssignmentData.title,
      description: props.editAssignmentData.description,
      startDate: props.editAssignmentData.startDate,
      endDate: props.editAssignmentData.endDate,
      batchId: props.editAssignmentData.batchId,
      maximumMarks: props.editAssignmentData.maximumMarks,
    });
    setFileUpload({
      file: props.editAssignmentData.file,
    })
  }, []);

  const handleEditAssignment = () => {
    let id = props.editAssignmentData?.id;
    if(props.editAssignmentData.file==""){
      Swal.fire({
        icon:'error',
        title: "File has not Added!",
      })
    }
    else{
      Swal.fire({
        icon:'success',
        title: "File Has Been Added Successfully!",
      })
    }
    let formDataIs = new FormData();
    formDataIs.append("file", fileUpload.file); // Access the 'file' property of fileUpload
    formDataIs.append("data", JSON.stringify(formEditData));

    Assignment.editAssignment(id, formDataIs, (response) => {
      if (response.status === "success") {
        Swal.fire({
          icon: "success",
          title: "Data Updated Successfully.",
        });
        props.handleAllAssignment();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error Occured While Updating Data!",
        });
      }
    });
    props.handleCloseEdit();
  };
  const handleEditChange = (event) => {
    setFormEditData({
      ...formEditData,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeFile = (event) => {
    const uploadFile = event.target.files[0];
    setFileUpload({
      file: uploadFile,
    });
  };

  

  return (
    <>
      <Dialog open={props.openEdit} onClose={props.handleCloseEdit} >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
           Edit Assignment
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={props.handleCloseEdit}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Title"
            type="text"
            name="title"
            fullWidth
            variant="outlined"
            defaultValue={props.editAssignmentData?.title}
            onChange={(event) => handleEditChange(event)}
          />

          <TextField
            autoFocus
            margin="dense"
            id="maximumMarks"
            label="Maximum Marks"
            type="number"
            fullWidth
            name="maximumMarks"
            variant="outlined"
            defaultValue={props.editAssignmentData?.maximumMarks}
            onChange={(event) => handleEditChange(event)}
          />
          {/* <InputLabel id="demo-simple-select-label" fullWidth>
            Batches
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="batchId"
            fullWidth
            value={Batches}
            label="Batches"
          >
            <MenuItem value={1}>Batches 1</MenuItem>
            <MenuItem value={2}>Batches 2</MenuItem>
            <MenuItem value={3}>Batches 3</MenuItem>
          </Select> */}
          <TextField
            autoFocus
            margin="dense"
            id="startDate"
            label="Start Date"
            type="Date"
            name="startDate"
            variant="outlined"
            defaultValue={props.editAssignmentData?.startDate}
            onChange={(event) => handleEditChange(event)}
            sx={{ width: "40%", marginRight: "20%" }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="endDate"
            label="End Date"
            type="Date"
            name="endDate"
            variant="outlined"
            defaultValue={props.editAssignmentData?.endDate}
            onChange={(event) => handleEditChange(event)}
            sx={{ width: "40%" }}
          />
          <TextField
            id="description"
            label="Description"
            fullWidth
            multiline
            name="description"
            rows={4}
            variant="outlined"
            defaultValue={props.editAssignmentData?.description}
            onChange={(event) => handleEditChange(event)}
            sx={{ marginTop: "2%", marginBottom: "2%" }}
          />
          <Button
              component="label"
              fullWidth
              name="file"
              id="file"
              sx={{ backgroundColor:"rgb(225, 223, 223)",paddingTop:"4%",
              paddingBottom:"4%",color:"gray"}}
              onChange={handleChangeFile}
            >
              {fileUpload?.file?`Selected File is  ${fileUpload?.file?.name}`:`Upload files`} 
              <VisuallyHiddenInput
                accept="txt,pdf,docx"
                type="file"
              />
            </Button>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            fullWidth
             
            onClick={() => {
              handleEditAssignment();
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default EditAssignment;
