import React, { useRef } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import users from 'modules/users';
import CachedIcon from '@mui/icons-material/Cached';
import groups from 'modules/groups';
import roles from 'modules/roles';
import departments from 'modules/departments';

const AddUser = props => {
  // const { getAllUsers } = props;
  const [pwd, setPwd] = React.useState();
  const contentRef = useRef(null);
  const currentDate = new Date().toISOString().split('T')[0];
  const [role, setRole] = React.useState();
  const [group, setGroup] = React.useState();
  const [department, setDepartment] = React.useState();
  // Set the initial state with the current date
  const [dob, setDob] = React.useState(currentDate);

  const Swal = useSwalWrapper();
  React.useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
    // getGroups();
    getRoles();
    // getDepartments();
    handleGeneratePassword();
  }, [props.close]);
  const handleGeneratePassword = () => {
    let pass = Math.random().toString(36).slice(2, 10);
    setPwd(pass);
  };
  const validationSchema = yup.object({
    userName: yup
      .string('Enter your username')
      .required('Username is required'),
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    password: yup
      .string('Enter your password')
      .required('Password is required'),
  });

  const handleAddEditUser = data => {
    if (props.data.id) {
      let name = props?.data?.userName ? props?.data?.userName : data.userName;
      name = name.trim();
      // users.getDistinctUser(name, response => {
      // if (response.status === 'success') {
      users.updateUser(props.data.id, data, response => {
        if (response.status === 'success') {
          props.close();
          props?.getAllUsers();
          Swal.fire({
            icon: 'success',
            title: 'User has been updated successfully.',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'There is an Error while updating user.',
          });
        }
      });
    } else {
      let userName = props?.data?.userName
        ? props?.data?.userName
        : data.userName;
      userName = userName.trim();
      users.getDistinctUser(userName, response => {
        if (response.status === 'success') {
          props.close();
          users.addUser(data, response => {
            if (response.status === 'success') {
              props?.getAllUsers();
              Swal.fire({
                icon: 'success',
                title: 'User has been added successfully.',
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'There is an Error while adding user.',
              });
            }
          });
          // Swal.fire({
          //   icon: 'success',
          //   title: 'UserName has been added successfully.',
          // });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'UserName already exists.',
          });
          props.close();
          props?.getAllUsers();
        }
      });
    }
  };
  // const getGroups = () => {
  //   groups.getAllGroups(response => {
  //     if (response.status === 'success') {
  //       setGroup(response.data);
  //     } else {
  //       setGroup('');
  //     }
  //   });
  // };
  // const getDepartments = () => {
  //   departments.getAllDepartments(response => {
  //     if (response.status === 'success') {
  //       setDepartment(response.data);
  //     } else {
  //       setDepartment('');
  //     }
  //   });
  // };

  const getRoles = () => {
    roles.getAllRoles(response => {
      if (response.status === 'success') {
        const dataWithoutFirstEntry = response.data.slice(1);
        setRole(dataWithoutFirstEntry);
      } else {
        setRole('');
      }
    });
  };
  const defaultSelectedRoleId = role && role[1]?.id;
  {
    console.log(role, 'role', defaultSelectedRoleId, 'default');
  }
  // const roles = ['Administrator', 'user', 'moderator'];

  //const departments = ['Production', 'R&D', 'Purchasing', 'Marketing'];
  return (
    <Div sx={{ py: { lg: 2 } }}>
      <Dialog open={props.open} onClose={props.close} maxWidth="lg">
        <Formik
          validateOnChange={true}
          initialValues={{
            ...props.data,
            password: props.data.id ? props.data.password : pwd,
            dob: props.data.id ? props.data.dob : dob,
            roleId: props.data.roleId ? props.data.roleId : 2,
            // departmentId:
            //   props?.data?.departmentId && props?.data?.departmentId,

            status: props?.data?.status ? props?.data?.status : true,
            languageId: props?.data?.languageId ? props?.data?.languageId : 1,
          }}
          // enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={data => {
            console.log(data, 'data');
            handleAddEditUser(data);
          }}
        >
          <Form style={{ textAlign: 'left' }} noValidate autoComplete="off">
            <DialogContent
              ref={contentRef}
              sx={{ overflowY: 'auto', maxHeight: '80vh' }}
            >
              <DialogTitle id="alert-dialog-title" textAlign="center">
                {props.data.id ? 'Edit User' : 'Add User'}
              </DialogTitle>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12} md={6} sm={6} sx={{ mb: 1, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name="firstName"
                    label="Firstname"
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={6} sx={{ mb: 1, mt: 1 }}>
                  <JumboTextField fullWidth name="lastName" label="Lastname" />
                </Grid>
                {props.data.id ? (
                  <Grid item xs={12} md={6} sm={6} sx={{ mb: 1, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="userName"
                      label="Username*"
                      disabled
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} md={6} sm={6} sx={{ mb: 1, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="userName"
                      label="Username*"
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6} sm={6} sx={{ mb: 1, mt: 1 }}>
                  <JumboTextField fullWidth name="email" label="Email*" />
                </Grid>
                <Grid item xs={12} md={6} sm={6} sx={{ mb: 1, mt: 1 }}>
                  <JumboTextField fullWidth name="address" label="Address*" />
                </Grid>
                <Grid item xs={12} md={6} sm={6} sx={{ mt: 1 }}>
                  <JumboTextField fullWidth name="phone" label="Phone Number" />
                </Grid>
                <Grid item xs={12} md={6} sm={6} sx={{ mt: 1 }}>
                  <JumboTextField
                    type="date"
                    fullWidth
                    name="dob"
                    label="DOB"
                    value={dob}
                    onChange={event => setDob(event.target.value)}
                  />
                </Grid>
                {!props.data.id && (
                  <Grid item xs={12} md={6} sm={6} sx={{ mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="password"
                      label="Password*"
                      value={pwd}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleGeneratePassword}>
                              <CachedIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                )}

                <Grid item xs={12} md={6} sm={6} sx={{ mb: 1, mt: 1 }}>
                  <JumboTextField fullWidth select name="roleId" label="Role">
                    {role &&
                      role.map(option => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                  </JumboTextField>
                </Grid>
                <Grid item xs={12} md={6} sm={6} sx={{ mb: 1, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    select
                    name="status"
                    label="Status"
                    value="enabled"
                  >
                    <MenuItem key="Enabled" value={true}>
                      Enabled
                    </MenuItem>
                    <MenuItem key="Disabled" value={false}>
                      Disabled
                    </MenuItem>
                  </JumboTextField>
                </Grid>
                <Grid item xs={12} md={6} sm={6} sx={{ mb: 1, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    select
                    name="languageId"
                    label="Language"
                  >
                    <MenuItem key="English" value={1}>
                      English
                    </MenuItem>
                    <MenuItem key="العربيه" value={2}>
                      العربيه
                    </MenuItem>
                  </JumboTextField>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" type="submit" autoFocus>
                {props.data.id ? 'Update' : 'Save'}
              </Button>
              <Button variant="contained" onClick={props.close}>
                {' '}
                Cancel
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    </Div>
  );
};

export default AddUser;
