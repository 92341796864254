import { useState, useEffect } from "react";
import { Grid, Chip, Box, Tab, Stack } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Typography } from "@material-ui/core";
import CheckIcon from "@mui/icons-material/Check";
import { useSelector } from "react-redux";
import UserProgress from "app/mmEsoft/module/UserProgress";
import campaigns from "modules/campaigns";
import JumboListNoDataPlaceHolder from "@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder";

export default function Courses({ batchId, userId }) {
  const [campTabValue, setCampTabValue] = useState("1");
  const [campaignListData, setCampaignListData] = useState([]);
  const [lessonData, setLessonData] = useState([]);
  const [progressData, setProgressData] = useState([]);

  const handleCampaignTab = (event, newValue) => {
    setCampTabValue(newValue);
  };

  //TODO CREATE TAB INSIDE COURSE TAB .
  //TODO INTERGRATE API OF CAMPAIGNLIST
  //TODO  SHOW LESSON AND INTERGRATE API
  //TODO SHOW VEDIO PROGRESS AND QUIZ PROGRESS

  const getAllCampaigns = () => {
    UserProgress.getAllCampaigns(userId, batchId, (response) => {
      if (response.status === "success") {
        console.log(response.data, "All Campaign");
        setCampaignListData(response?.data);
      } else {
        console.log("error while loading the All Campaign ");
      }
    });
  };
  useEffect(() => {
    getAllCampaigns();
  }, []);

  // getLessonsByCampaignId
  const getCampaignLessons = (id) => {
    campaigns.getLessonsByCampaignId(id, (response) => {
      if (response.status === "success") {
        console.log(response.data, "lesson Data");
        setLessonData(response?.data);
      } else {
        console.log("error while loading the lessons ");
      }
    });
  };

  // //user progress
  const getUserProgress = (id) => {
    UserProgress.getUserProgress(id, userId, (response) => {
      if (response.status === "success") {
        console.log(response.data, "progress");
        setProgressData(response?.data);
      } else {
        console.log("error while getting the UserProgress  ");
      }
    });
  };
  const handleTabClick = (id) => {
    getCampaignLessons(id);
    getUserProgress(id);
  };
  return (
    <>
      <Box sx={{ typography: "body1" }}>
        <TabContext value={campTabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleCampaignTab}
              aria-label="lab API tabs example"
            >
              {campaignListData.map((item, index) => {
                return (
                  <>
                    <Tab
                      label={item?.title}
                      value={campTabValue} // Make sure this matches the state
                      onClick={() => handleTabClick(item?.id)}
                      id="tabTextColor"
                      active
                    />
                  </>
                );
              })}
            </TabList>
          </Box>
          {lessonData.length > 0 ? (
            lessonData?.map((lData) => {
              return (
                <>
                  <TabPanel
                    value={campTabValue} // Make sure this matches the state
                    id="styleText"
                    sx={{
                      borderBottom: "1px solid ",
                      borderColor: "divider",
                    }}
                  >
                    <Box
                      style={{
                        boxShadow: "2px 5px 2px #DBD7D2",
                        marginTop: "20px",

                        paddingLeft: "25px",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <item>
                            <Typography variant="h5">
                              {lData?.lessonName}
                            </Typography>{" "}
                          </item>
                        </Grid>
                      </Grid>
                      <Box>
                        <Stack direction="row" spacing={2}>
                          {progressData.map((data, index) => {
                            return (
                              <>
                                <Stack direction="row" spacing={3}>
                                  {index === 0 ? (
                                    <Typography variant="h6">
                                      Quiz
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "25px",
                                        }}
                                      >
                                        {data?.quizScore}/
                                      </span>
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "25px",
                                          color: "#82b440",
                                        }}
                                      >
                                        {lData?.maxQuestions}
                                      </span>
                                    </Typography>
                                  ) : null}
                                </Stack>
                                <Box>
                                  <Stack direction="row" spacing={2}>
                                    <Box>
                                      <Chip
                                        label={
                                          <>
                                            vedio {index + 1}
                                            <CheckIcon
                                              style={{
                                                fontWeight: "600",
                                                fontSize: "27px",
                                                color: !data.videoStatus
                                                  ? "gray"
                                                  : "#82b440",
                                              }}
                                            />
                                          </>
                                        }
                                        variant="outlined"
                                      />
                                    </Box>
                                  </Stack>
                                </Box>
                              </>
                            );
                          })}
                        </Stack>
                      </Box>
                    </Box>
                  </TabPanel>
                </>
              );
            })
          ) : (
            <JumboListNoDataPlaceHolder />
          )}
          {}
        </TabContext>
      </Box>

      {/* <Box style={{boxShadow:'2px 5px 2px #DBD7D2',marginTop:'20px',paddingLeft:'25px'}}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <item> <h1> React </h1>  </item>
        </Grid>
        <Grid item xs={6}>
          <item> <h2> Quiz 10/9 </h2> </item>
        </Grid>
      </Grid>
      <Box sx={{marginTop:'20px',paddingBottom:'25px'}}>
      <Chip label="Video 1" />
      <Chip label="Video 2" sx={{marginLeft:'10px'}}/>
      <Chip label="Video 3" sx={{marginLeft:'10px'}}/>
      </Box>
    </Box>
    <Box style={{boxShadow:'2px 5px 2px #DBD7D2',marginTop:'20px',paddingLeft:'25px'}}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <item> <h1> HTML </h1> </item>
        </Grid>
        <Grid item xs={6}>
          <item> <h2> Quiz 10/8 </h2> </item>
        </Grid>
      </Grid>
      <Box sx={{marginTop:'20px',paddingBottom:'25px'}}>
      <Chip label="Video 1" />
      <Chip label="Video 2" sx={{marginLeft:'10px'}}/>
      <Chip label="Video 3" sx={{marginLeft:'10px'}}/>
      </Box>
    </Box>
    <Box style={{boxShadow:'2px 5px 2px #DBD7D2',marginTop:'20px',paddingLeft:'25px'}}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <item> <h1> Css </h1> </item>
        </Grid>
        <Grid item xs={6}>
          <item> <h2> Quiz 10/6 </h2> </item>
        </Grid>
      </Grid>
      <Box sx={{marginTop:'20px',paddingBottom:'25px'}}>
      <Chip label="Video 1" />
      <Chip label="Video 2" sx={{marginLeft:'10px'}}/>
      <Chip label="Video 3" sx={{marginLeft:'10px'}}/>
      </Box>
    </Box> */}
    </>
  );
}
