import React, { useState, useEffect } from 'react';

import routes, { routesForUser } from './routes';

import useJumboRoutes from '@jumbo/hooks/useJumboRoutes';
import { useSelector } from 'react-redux';

const AppRoutes = () => {
  const { isAuthenticated, user } = useSelector(({ login }) => login);

  // const { isAuthenticatedGuest, guestUser } =

  const [newRoutes, setNewRoutes] = React.useState(routes);

  React.useEffect(() => {
    if ((user?.roleId === 2 || user?.roleId === 5) && isAuthenticated) {
      setNewRoutes(routesForUser);
    } else if (user?.roleId === 1 && isAuthenticated) {
      setNewRoutes([]);
    }
  }, [user?.roleId]);
  const appRoutes = useJumboRoutes(newRoutes);

  const checkGuestLogin = () => {
    const decodedCookie = decodeURIComponent(document.cookie);

    console.log('test11111');

    // if (decodedCookie) {

    const cookieArray = decodedCookie.split(';');
    const d = cookieArray;
    // localStorage.setItem('Auth1', cookieArray[0].split('=')[1]);
    // localStorage.setItem('authData2', JSON.parse(cookieArray[1].split('=')[1]));
    return true, cookieArray[1];
    // }
  };
  // checkGuestLogin();

  return <React.Fragment>{appRoutes}</React.Fragment>;
};
export default AppRoutes;
