import React from 'react';
import campaigns from 'modules/campaigns';

import * as yup from 'yup';
import certificates from 'modules/certificates';
import { Button, Grid, MenuItem, TextField, Card } from '@mui/material';

import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';

function CampaignCertificate({ campaign, Swal }) {
  const [certificate, setCertificate] = React.useState();

  const [certificateId, setCertificateId] = React.useState();
  const [certificateData, setCertificateData] = React.useState([]);
  const getAllCertificates = () => {
    certificates.getAll(response => {
      if (response.status === 'success') {
        setCertificateData(response.data);
      } else {
        setCertificateData([]);
      }
    });
  };
  React.useEffect(() => {
    getCertificatesByCampaignId(campaign.id);
    getAllCertificates();
  }, []);

  const getCertificate = id => {
    certificates.getImageById(id, response => {
      if (response.status === 'success') {
        setCertificate(response.data);
        console.log(response.data, 'certificateeeeeeee');
      } else {
        setCertificate();
      }
    });
  };
  const getCertificatesByCampaignId = () => {
    campaigns.getCampaign(campaign.id, response => {
      if (response.status === 'success') {
        setCertificateId(response.data?.certificateId);
        getCertificate(response.data?.certificateId);
        console.log(response.data.certificateId, 'certificateeeeeeee');
      } else {
        setCertificateId();
      }
    });
  };
  const saveCampaignCertificate = data => {
    let campaignData = {
      ...data,
      campaignId: campaign.id,
      certificateId: certificateId,
    };

    campaigns.updateCampaign(
      campaignData.campaignId,
      campaignData,
      response => {
        if (response.status === 'success') {
          getCertificate(certificateId);
          Swal.fire({
            icon: 'success',
            title: 'Certificate has been Updated successfully.',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'there is an issue While updating Certificate',
          });
        }
      }
    );
  };
  console.log('Initial certificateId:', certificateId);

  return (
    <>
      <Grid container sx={{ display: 'flex' }} columnSpacing={1}>
        <Grid xs={10} md={10} sm={8} sx={{ mb: 2, mt: 1 }}>
          {certificateData.length > 0 ? (
            <TextField
              fullWidth
              select
              name="certificateId"
              label="Add Certificate"
              value={certificateId}
              onChange={e => {
                debugger;
                console.log(e, 'eventtttttttttttt');
                setCertificateId(e.target.value);
              }}
            >
              {certificateData.map(c => (
                <MenuItem key={c.id} value={c.id}>
                  {c.certificateName}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <p>Loading certificates...</p>
          )}
        </Grid>
        <Grid
          sx={{
            ml: 3,
            mt: 1,
            mb: 2,
            display: 'flex',
          }}
        >
          <Button
            variant="contained"
            onClick={() => saveCampaignCertificate(certificateId)}
            autoFocus
            width="80"
          >
            Save
          </Button>
        </Grid>
      </Grid>
      {certificate ? (
        <Card>
          <img
            style={{
              display: 'block',
              margin: '20px',
              // width: '900px',
              height: '500px',
            }}
            src={URL.createObjectURL(new Blob([certificate]))}
            alt="Certificate"
          />
        </Card>
      ) : (
        ''
      )}
    </>
  );
}

export default CampaignCertificate;
