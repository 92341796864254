import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import Experience from 'app/mmEsoft/module/Experience';
import React from 'react';
import Swal from 'sweetalert2';

const AddExperienceDialog = ({
	getExperience,
	candidate,
	open,
	setOpen,
	experience,
}) => {
	const [employData, setEmployData] = React.useState({
		companyName: '',
		candidateId: candidate.id,
		position: '',
		description: '',
		startDate: null,
		endDate: null,
		totalExperience: '',
		overallExperience: '',
		employementType: '',
		ctc: '',
		eCtc: '',
		noticePeriod: null,
	});
	const addEmploy = () => {
		console.log(employData);
		Experience.addEmploy(employData, response => {
			if (response.status === 'success') {
				getExperience();
				Swal.fire({
					icon: 'success',
					title: 'Experience has been added successfully',
				});
			} else {
				Swal.fire({
					icon: 'error',
					title: 'There was an error while adding the Experience',
				});
			}
		});
		setOpen(false);
	};

	const handleChange = event => {
		setEmployData({ ...employData, [event.target.name]: event.target.value });
	};
	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			maxWidth='md'
		>
			<DialogTitle>
				{experience ? `Update Employment Details` : `Add Employment Details`}
			</DialogTitle>
			<DialogContent>
				<FormControl
					sx={{ marginTop: '10px' }}
					fullWidth
				>
					<InputLabel id='demo-simple-select-label'>Employment Type</InputLabel>
					<Select
						value={employData.employementType}
						onChange={handleChange}
						label='Employment Type'
						inputProps={{
							name: 'employementType',
							id: 'employementType',
						}}
					>
						<MenuItem value='Full-Time'>Full-Time</MenuItem>
						<MenuItem value='Part-Time'>Part-Time</MenuItem>
						{/* Add more employment type options as needed */}
					</Select>
				</FormControl>
				<TextField
					margin='dense'
					id='totalExperience'
					name='totalExperience'
					label='Total Experience'
					type='text'
					fullWidth
					value={employData.totalExperience}
					onChange={handleChange}
				/>

				<TextField
					margin='dense'
					id='companyName'
					name='companyName'
					label='Current Company Name'
					type='text'
					fullWidth
					value={employData.companyName}
					onChange={handleChange}
				/>
				<TextField
					margin='dense'
					id='position'
					name='position'
					label='Current Designation'
					type='text'
					fullWidth
					value={employData.position}
					onChange={handleChange}
				/>
				<TextField
					margin='dense'
					id='description'
					name='description'
					label='Skills Used'
					type='text'
					fullWidth
					value={employData.description}
					onChange={handleChange}
				/>

				<TextField
					margin='dense'
					id='ctc'
					name='ctc'
					label='Current CTC'
					type='number'
					fullWidth
					value={employData.ctc}
					onChange={handleChange}
				/>
				<TextField
					margin='dense'
					id='eCtc'
					name='eCtc'
					label='Expected CTC'
					type='number'
					fullWidth
					value={employData.eCtc}
					onChange={handleChange}
				/>
				<Grid
					container
					spacing={2}
				>
					<Grid
						item
						md={6}
					>
						<Typography fontSize={'14px'}>Start Date</Typography>
						<TextField
							margin='dense'
							id='startDate'
							name='startDate'
							type='date'
							fullWidth
							value={employData.startDate}
							onChange={handleChange}
						/>
					</Grid>
					<Grid
						item
						md={6}
					>
						<Typography fontSize={'14px'}>End Date</Typography>
						<TextField
							margin='dense'
							id='endDate'
							name='endDate'
							type='date'
							fullWidth
							value={employData.endDate}
							onChange={handleChange}
						/>
					</Grid>
				</Grid>
				<TextField
					margin='dense'
					id='noticePeriod'
					name='noticePeriod'
					label='Notice Period '
					type='number'
					fullWidth
					value={employData.noticePeriod}
					onChange={handleChange}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					variant='outlined'
					onClick={() => setOpen(false)}
				>
					Cancel
				</Button>
				<Button
					variant='outlined'
					onClick={e => {
						e.preventDefault();
						addEmploy();
					}}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default AddExperienceDialog;
