import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import { Box, Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder';
import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder';
import ResultData from 'app/mmEsoft/module/ResultData';
import { da } from 'date-fns/locale';

const columns = [
  { field: 'id', headerName: 'S.No', width: 70 },
  { field: 'activityName', headerName: 'Activity', width: 130 },
  { field: 'activityDate', headerName: 'ActivityDate', width: 140 },
  {
    field: 'present',
    headerName: 'Present/Absent',
    width: 140,
    renderCell: (params) => {
      return params.value ? 'P' : 'A';
    },
  },
];

const useStyles = makeStyles({
  root: {
    border: '2px solid #e0e0e0',
    '& .MuiDataGrid-columnsContainer': {
      borderBottom: '2px solid #e0e0e0',
    },
    '& .MuiDataGrid-cell': {
      borderBottom: '2px solid #e0e0e0',
    },
    '& .MuiDataGrid-row': {
      borderTop: '2px solid #e0e0e0',
    },
  },
});

export default function Attendance({ userId, userName, totalAttendence }) {
  console.log(userId, userName, totalAttendence);
  const classes = useStyles();
  const [attendenceData, setAttendanceData] = useState([]);

  useEffect(() => {
    getAttendenceByCandidateId();
  }, [userId]);

  const getAttendenceByCandidateId = () => {
    ResultData.getAttendenceByCandidateId(userId, (response) => {
      if (response.status === 'success') {
        console.log(response);
        setAttendanceData(response?.data);
      } else {
        console.log('Error');
      }
    });
  };

  // to get total attendence
  let count = 0;
  let AbsentDays = 0;
  attendenceData.map((data) => {
    if (data.present == true) {
      count = count + 1;
    }
    AbsentDays = attendenceData.length - count;
  });

  return (
    <Box>
      <Box sx={{ marginBottom: '10px' }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <item>User Name: {userName} </item>
          </Grid>
          <Grid item xs={4}>
            <item>
              Total Attendence: {count} / {attendenceData.length}{' '}
            </item>
          </Grid>
          <Grid item xs={4}>
            <item>
              Attendance Percentage:
              {attendenceData.length
                ? ((count / attendenceData.length) * 100).toFixed(1)
                : '0'}{' '}
              %
            </item>
          </Grid>
        </Grid>
      </Box>
      <div style={{ height: 350, width: '100%' }}>
        {attendenceData.length === 0 ? (
          <Box>
            <JumboListNoDataPlaceholder>
              <NoDataPlaceholder />
            </JumboListNoDataPlaceholder>
          </Box>
        ) : (
          <DataGrid
            rows={attendenceData}
            columns={columns}
            classes={classes}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
          />
        )}
      </div>
      <Box sx={{ marginTop: '10px' }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <item>Total Present Days = {count} </item>
          </Grid>
          <Grid item xs={6}>
            <item>Total Absent Days = {AbsentDays} </item>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
