import React, { useEffect, useState } from 'react';
import {
  TableCell,
  TableRow,
  Typography,
  Dialog,
  DialogContent,
  Button,
} from '@mui/material';
import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import videos from 'modules/videos';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import AddVideos from './AddVideos';

import './Video.css';
import data1 from './myVideo/data.json';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReactPlayer from 'react-player';
import auth from 'modules/auth';
import { appServerURL } from 'modules/appApi';

const Video = (props) => {
  console.log(props, 'propssssss');
  let videoRef = React.useRef(null);

  let prevPlayed = React.useRef(null);
  const Swal = useSwalWrapper();
  const { showDialog, hideDialog } = useJumboDialog();
  const [newQuestions, setNewQuestions] = useState([]);
  const [showMcq, setShowMcq] = useState({ show: false, index: null });
  const [answer, setAnswer] = useState(null);

  const [videoDialog, setVideoDialog] = React.useState({
    open: false,
    data: {
      title: '',
      title1: '',
    },
  });
  const [multiSelect, setMultiSelect] = useState(false);
  const [checkedStates, setCheckedStates] = useState([
    false,
    false,
    false,
    false,
  ]);

  let [playing, setPlaying] = useState(false);
  let [playedSeconds, setPlayedSeconds] = useState(0);

  let pip = false;
  let controls = true;
  let light = false;
  let volume = 0.8;
  let muted = false;

  let playbackRate = 1.0;
  let loop = false;

  const [videoSrc, setVideoSrc] = useState();

  useEffect(() => {
    getVideoURL(props?.video?.url);
    // setVideoSrc(

    //   // appServerURL +
    //   //   'api/video/' +
    //   //   props?.video?.url +
    //   //   '?auth=' +
    //   //   auth.getToken().token

    // );

    // fix defualt interactive questions issue
    let data = props?.video?.interaction
      ? JSON.parse(props.video.interaction)
      : null;

    if (data && data.layers) {
      const newQuestionss = data.layers.map((l) => ({
        timeStamp: l.timeStamp.split(':')[1],
        question: l.config.question,
        options: l.config.answers,
        correctAnswer: l.config.correctAnswer,
        skippable: l.skippable,
        type: l.type,
      }));
      setNewQuestions(newQuestionss);
    } else {
      setNewQuestions([]);
    }
  }, [props?.video]);

  const handleSeek = (e) => {
    if (props?.lesson?.seek == false) {
      var delta =
        videoRef.current.getCurrentTime() - prevPlayed.current.playedSeconds;
      if (delta > 0.01) {
        videoRef.current.seekTo(prevPlayed.current.playedSeconds);
      }
    }
  };

  const handleVideoChange = (_videoData) => {
    if (props.userVideoData?.length == 0) {
      if (_videoData?.length > 0) {
        props?.handleListItemClickCallBack(1, props?.lesson, _videoData[0]);
        props?.setVideo(_videoData[0]);
      }
    } else {
      for (let i = 0; i < props.userVideoData?.length; i++) {
        const uvData = props.userVideoData[i];
        let video = _videoData?.filter((vData) => vData.id == uvData.videoId);
        if (video.length > 0) {
          props?.setVideo(_videoData[props?.video?.id]);
          props?.handleListItemClickCallBack(
            1,
            props?.lesson,
            _videoData[props?.video?.id]
          );
          props?.setLesson({
            ...props?.lesson,
            showVideoWatched: false,
          });
        } else {
          let indexx = props.videoData?.indexOf(_videoData[props?.video?.id]);
          props?.setVideo(_videoData[indexx + 1]);
          props?.handleListItemClickCallBack(
            1,
            props?.lesson,
            _videoData[indexx + 1]
          );

          props?.setLesson({
            ...props?.lesson,
            showVideoWatched: false,
          });
        }
      }
    }
  };
  const getVideoURL = (videoName, response) => {
    videos.getVideoURL(videoName, (response) => {
      if (response.status === 'success') {
        setVideoSrc(response.data.url);
      } else {
        setVideoSrc([]);
      }
    });
  };

  function dialogClick(e) {
    if (e.target.classList[0] != 'MuiDialogContent-root') {
      return;
    }
  }
  var newTime;
  let newQuestionss = [];
  async function onPlayerReady(player1) {}

  const onVideoTimeUpdate = (state) => {
    prevPlayed.current = state;
    let duration = playedSeconds != 0 ? playedSeconds : state.playedSeconds;

    newQuestions?.map((nq, index) => {
      let timeStamp = +nq?.timeStamp;
      console.log(
        duration + ' > ' + timeStamp,
        ' nq.isViewed = ' + nq.isViewed
      );
      if (duration >= timeStamp && duration <= timeStamp + 1 && !nq.isViewed) {
        newQuestions[index].isViewed = true;
        setPlaying(false);
        pauseVid(index);
        if (nq.type != 'single choice question') {
          setMultiSelect(true);
        } else {
          setMultiSelect(false);
        }
      }
    });
  };

  function onVideoEnd() {}

  const handleButtonClick = (index) => {
    const newCheckedStates = [...checkedStates];
    newCheckedStates[index] = !newCheckedStates[index];
    setCheckedStates(newCheckedStates);

    if (!multiSelect) {
      for (let i = 0; i < newCheckedStates.length; i++) {
        if (i !== index) {
          newCheckedStates[i] = false;
        }
      }
    }
  };
  const handleSubmit = () => {
    let ans = null;
    let count = 0;
    if (
      newQuestions[showMcq.index]?.correctAnswer ===
      checkedStates.indexOf(true) + 1
    ) {
      ans = true;
    } else if (newQuestions[showMcq.index]?.correctAnswer.length > 0) {
      newQuestions[showMcq.index]?.correctAnswer.map((c) => {
        let newVal = checkedStates.indexOf(true) + 1;
        if (c === newVal) {
          count = count + 1;
          setCheckedStates(
            ...checkedStates,
            (checkedStates[newVal - 1] = false)
          );
        }
      });
      if (newQuestions[showMcq.index]?.correctAnswer.length === count) {
        ans = true;
      } else {
        ans = false;

        newQuestions[showMcq.index].isViewed = false;
      }
    }

    if (ans == true) {
      setTimeout(() => {
        setShowMcq({ show: false });

        setPlaying(true);
        setCheckedStates([false, false, false, false]);
        setAnswer(null);
      }, 1500);
    }
    setAnswer(ans);
  };

  function seeVideoAgain() {
    videoRef.current.seekTo(0);
    setShowMcq({ show: false });
    setPlaying(true);
    setCheckedStates([false, false, false, false]);
    setAnswer(null);
  }
  const buttonStyles = {
    marginRight: '1rem',
    width: '150px',
  };
  function pauseVid(index) {
    setShowMcq({ index: index, show: true });
  }
  const handleCloseMcq = () => {
    setShowMcq({ show: false });
  };

  const handleDelete = () => {
    videos.deleteVideo(props.video.id, (response) => {
      if (response.status === 'success') {
        props?.getAllVideosByLesson(props.lessonId);
        Swal.fire({
          icon: 'success',
          title: 'Video has been deleted successfully.',
        });
        hideDialog();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while deleting video.',
        });
      }
    });
  };

  const handleEdit = () => {
    setVideoDialog({ ...videoDialog, data: props?.video, open: true });
  };

  const closeDialog = () => {
    setVideoDialog({ ...videoDialog, open: false });
  };

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case 'delete':
        showDialog({
          variant: 'confirm',
          title: 'Are you sure about deleting this Video?',
          content: "You won't be able to recover this video later",
          onYes: () => handleDelete(),
          onNo: hideDialog,
        });

        break;
      case 'edit':
        handleEdit();
        break;
    }
  };

  return (
    <>
      <div>
        <div>
          <TableRow
            sx={{
              boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
              borderRadius: '12px',
              backgroundColor: 'rgb(255, 255, 255)',
              width: '100%',
              display: 'table',
            }}
          >
            <TableCell colSpan={3}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '8%',
                }}
              >
                <ReactPlayer
                  // Disable download button
                  config={{
                    file: { attributes: { controlsList: 'nodownload' } },
                  }}
                  ref={videoRef}
                  className='react-player'
                  width='100%'
                  height={props.flag ? '300px' : '300px'}
                  url={videoSrc}
                  pip={pip}
                  playing={playing}
                  controls={controls}
                  // controls={props.controls ? true : false}
                  light={light}
                  loop={loop}
                  playbackRate={playbackRate}
                  volume={volume}
                  muted={muted}
                  onReady={onPlayerReady}
                  onStart={() => console.log('onStart')}
                  onSeek={handleSeek}
                  onProgress={onVideoTimeUpdate}
                  onPlay={(e) => setPlaying(true)}
                  onPause={(e) => setPlaying(false)}
                  onEnded={(e) => onVideoEnd(e)}
                />
              </div>
            </TableCell>

            <JumboDdMenu
              icon={<MoreVertIcon />}
              menuItems={menuItems}
              onClickCallback={handleItemAction}
            />
          </TableRow>
        </div>
      </div>

      <Dialog
        open={showMcq.show}
        onClose={handleCloseMcq}
        onClick={dialogClick}
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <Typography variant='h3'>
            {newQuestions[showMcq.index]?.question}
          </Typography>
          <br />

          {newQuestions[showMcq.index]?.options?.map((label, index) => (
            <div>
              <Button
                key={index}
                sx={{
                  border: '2px solid #ccc',
                  borderRadius: '7px',
                  padding: '5px 10px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: checkedStates[index]
                    ? 'lightgrey'
                    : 'transparent',
                  cursor: 'pointer',
                }}
                fullWidth
                onClick={() => handleButtonClick(index)}
              >
                {newQuestions[showMcq.index]?.type == 'single choice question'
                  ? label
                  : label.name}
                {multiSelect ? (
                  <>
                    <input
                      type='checkbox'
                      checked={checkedStates[index]}
                      readOnly
                    />
                  </>
                ) : (
                  <>
                    <input
                      type='radio'
                      name={`radio_${index}`} // Use a unique name for each radio button group
                      checked={checkedStates[index]}
                      readOnly
                    />
                  </>
                )}
              </Button>
              <br />
            </div>
          ))}
          <div
            style={{
              display: 'flex',
              justifyContent:
                answer == false || answer ? 'space-between' : 'flex-end',
              alignItems: 'center',
            }}
          >
            {answer == true && <h3 style={{ color: 'green' }}>CORRECT</h3>}
            {answer == false && (
              <div>
                <h3 style={{ color: 'red' }}>INCORRECT</h3>
              </div>
            )}
            {newQuestions[showMcq.index]?.skippable == true &&
              answer == null && (
                <div>
                  <Button
                    variant='contained'
                    sx={{
                      border: '2px solid #ccc',
                      borderRadius: '7px',
                      display: 'flex',
                      width: '110px',
                    }}
                    onClick={() => {
                      setShowMcq({ show: false });
                      setPlaying(true);
                      setCheckedStates([false, false, false, false]);
                      setAnswer(null);
                    }}
                  >
                    Skip
                  </Button>
                </div>
              )}
            &nbsp; &nbsp;
            {answer == null && (
              <Button
                sx={{
                  border: '2px solid #ccc',
                  borderRadius: '7px',
                  display: 'flex',
                  width: '130px',
                }}
                onClick={() => {
                  if (props.flag) {
                    setShowMcq({ show: false });
                    setPlaying(true);
                    setCheckedStates([false, false, false, false]);
                    setAnswer(null);
                  } else {
                    handleSubmit();
                  }
                }}
              >
                Submit
              </Button>
            )}
            &nbsp; &nbsp;
            {answer == false && (
              <Button
                variant='contained'
                sx={{
                  border: '2px solid #ccc',
                  borderRadius: '7px',
                  width: '180px',
                  marginLeft: '20px',
                }}
                onClick={(e) => seeVideoAgain()}
              >
                See Video Again
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>

      {videoDialog.open && (
        <AddVideos
          open={videoDialog.open}
          close={closeDialog}
          data={videoDialog.data}
          lessonId={props?.lesson?.lessonId}
          getAllVideosByLesson={props?.getAllVideosByLesson}
        />
      )}
    </>
  );
};

export default Video;

const menuItems = [
  { icon: <EditIcon />, title: 'Edit', action: 'edit' },
  { icon: <DeleteIcon />, title: 'Delete', action: 'delete' },
];
