import "./AddEmailCampaign.css";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  DialogContent,
  Grid,
  InputLabel,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Email from "app/mmEsoft/module/Email";
import { useEffect, useState } from "react";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import emailTemplate from "modules/emailTemplate";

function AddEmailCampaign(props) {
  const [emailTemplateList, setEmailTemplateList] = useState([]);
  const [disable, setDisable] = useState(false);
  const [labelData, setLabelData] = useState([]);
  const [addData, setAddData] = useState({
    title: "",
    emailTemplate: "",
    startDate: "",
    endDate: "",
    repeat: "",
    days: "",
    labels: "",
  });
  const [days, setDays] = useState([
    { id: "7", name: "S", selected: false, value: "Sunday" },
    { id: "1", name: "M", selected: false, value: "Monday" },
    { id: "2", name: "T", selected: false, value: "Tuesday" },
    { id: "3", name: "W", selected: false, value: "Wednesday" },
    { id: "4", name: "T", selected: false, value: "Thursday" },
    { id: "5", name: "F", selected: false, value: "Friday" },
    { id: "6", name: "S", selected: false, value: "Saturday" },
  ]);
  const [session, setSession] = useState({
    subject: "",
    recurrence: {
      pattern: {
        type: "weekly",
        interval: 1,
        daysOfWeek: [],
      },
    },
  });
  const Swal = useSwalWrapper();

  useEffect(() => {
    getAllEmailTemplates();
    emailLabel();
  }, []);

  const handleChange = (event) => {
    setAddData((prevData) => ({
      ...prevData,
      [event.target.name]: event.target.value,
    }));
  };

  const handleLabelsChange = (event, newValues) => {
    setAddData((prevData) => ({
      ...prevData,
      labels: newValues.join(","), 
    }));
  };

  const handleDaily = (param) => {
    if (param === "daily") {
      setDisable(true);
      setDays(days.map((day) => ({ ...day, selected: true })));

      // Update addData with all days selected and set repeat to "Daily"
      setAddData((prevData) => ({
        ...prevData,
        repeat: "Daily",
        days: days.map((day) => day.value).join(","),
      }));
    } else {
      setDisable(false);
      setDays(days.map((day) => ({ ...day, selected: false })));

      // Update addData to set repeat to "Weekly"
      setAddData((prevData) => ({
        ...prevData,
        repeat: "Weekly",
      }));
    }
  };

  const daysClicked = (dayId) => {
    if (!disable) {
      setDays((prevDays) => {
        const updatedDays = prevDays.map((day) =>
          day.id === dayId ? { ...day, selected: !day.selected } : day
        );

        // Update addData with the selected days using the updatedDays variable
        setAddData((prevData) => {
          const selectedDays = updatedDays
            .filter((day) => day.selected)
            .map((day) => day.value);
          return {
            ...prevData,
            days: selectedDays.join(","),
          };
        });

        return updatedDays;
      });
    }
  };

  //   Add API
  const handleSubmit = () => {
    Email.addEmail(addData, (response) => {
      if (response.status === "success") {
        Swal.fire({
          icon: "success",
          title: "Email Added Successfully.",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error Occured While Adding Email",
        });
      }
    });
    props.handleClose();
  };
  // Add API End

  // Email template dropdown API
  const getAllEmailTemplates = () => {
    emailTemplate.getAllEmailTemplates((response) => {
      if (response.status === "success") {
        setEmailTemplateList(response.data);
      } else {
        setEmailTemplateList([]);
      }
    });
  };
  // Email template dropdown API End

  // Label Api
  const emailLabel = () => {
    Email.emailLabel((response) => {
      if (response.status === "success") {
        setLabelData(response.data);
      } else {
        setEmailTemplateList([]);
      }
    });
  };
  // Label Api End

  return (
    <>
      <Box>
        <DialogContent>
          <Grid container columnSpacing={10}>
            <Grid item xs={12}>
              <Typography variant="h2" style={{textAlign:'center'}}> Add Email Campaign </Typography>
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ marginTop: "4%", marginBottom: "1%" }}>
                Title
              </InputLabel>
              <TextField
                fullWidth
                className="title"
                autoFocus
                margin="dense"
                id="title"
                type="text"
                name="title"
                variant="outlined"
                onChange={handleChange}
              />
              <Typography id="startDateError" color="error"></Typography>
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                id="demo-simple-select-label"
                fullWidth
                sx={{ marginTop: "2%", marginBottom: "2%" }}
              >
                Email Templates
              </InputLabel>
              <TextField
                fullWidth
                required
                id="emailTemplate"
                name="emailTemplate"
                size="small"
                variant="outlined"
                select
                onChange={handleChange}
              >
                {emailTemplateList.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.title}
                  </MenuItem>
                ))}
              </TextField>

              <Typography id="titleError" color="error"></Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel sx={{ marginTop: "4%", marginBottom: "1%" }}>
                Start Date
              </InputLabel>
              <TextField
                fullWidth
                className="startDateAdd"
                autoFocus
                margin="dense"
                id="startDate"
                type="Date"
                name="startDate"
                variant="outlined"
                onChange={handleChange}
              />
              <Typography id="startDateError" color="error"></Typography>
            </Grid>
            <Grid item xs={12} md={6} id="endDateGrid">
              <InputLabel sx={{ marginTop: "4%", marginBottom: "1%" }}>
                End Date
              </InputLabel>
              <TextField
                fullWidth
                className="endDateAdd"
                autoFocus
                margin="dense"
                id="endDate"
                type="Date"
                name="endDate"
                variant="outlined"
                onChange={handleChange}
              />
              <Typography id="endDateError" color="error"></Typography>
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                id="demo-simple-select-label"
                fullWidth
                sx={{ marginTop: "2%", marginBottom: "2%" }}
              >
                Repeat
              </InputLabel>
              <TextField
                fullWidth
                onChange={handleChange}
                select
                defaultValue={"Weekly"}
                size="small"
                sx={{ minWidth: 150, height: 20 }}
              >
                <MenuItem
                  value="Weekly"
                  onClick={() => {
                    setSession({
                      ...session,
                      recurrence: {
                        ...session.recurrence,
                        pattern: {
                          type: "Weekly",
                        },
                      },
                    });
                    handleDaily("weekly");
                  }}
                >
                  Weekly
                </MenuItem>
                <MenuItem
                  value="Daily"
                  onClick={() => {
                    setSession({
                      ...session,
                      recurrence: {
                        ...session.recurrence,
                        pattern: {
                          type: "Daily",
                        },
                      },
                    });
                    handleDaily("daily");
                  }}
                >
                  Daily
                </MenuItem>
              </TextField>
              <Typography id="titleError" color="error"></Typography>
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ marginTop: "5%", marginBottom: "2%" }}>
                Days
              </InputLabel>
              <Stack direction="row" spacing={2}>
                {days.map((day) => (
                  <Chip
                    key={day.id}
                    label={day.name}
                    variant="outlined"
                    className={`days${day.selected ? " selected" : ""}`}
                    style={{
                      backgroundColor: day.selected ? "#f2f2f2" : "transparent",
                    }}
                    onClick={() => daysClicked(day.id)}
                  />
                ))}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ marginTop: "2%", marginBottom: "2%" }}>
                Labels
              </InputLabel>
              <Autocomplete
                multiple
                id="labels-autocomplete"
                options={labelData.map((option) => option.code)}
                onChange={handleLabelsChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option}
                      variant="outlined"
                      className="label_chip"
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={props.handleClose}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={handleSubmit}>
            Save
          </Button>
        </DialogActions>
      </Box>
    </>
  );
}
export default AddEmailCampaign;
