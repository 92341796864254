import React, { useEffect } from 'react';

import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
	Typography,
	Box,
	MenuItem,
	Select,
	InputLabel,
	Grid,
} from '@mui/material';
import {
	Table,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';

import code from '@mui/icons-material/Code';
import Div from '@jumbo/shared/Div';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { Delete, Edit } from '@mui/icons-material';
import Education from '../../../../../mmEsoft/module/Education';
import EditQualification from './editQualification';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Experience from 'app/mmEsoft/module/Experience';
import GetExperience from '../experience/GetExperience';
const AddQualification = props => {
	const [open, setOpen] = React.useState(false);
	const Swal = useSwalWrapper();
	const [education, setEducation] = React.useState([]);
	const [selectedEducation, setSelectedEducation] = React.useState([]);
	const [openEdit, setOpenEdit] = React.useState(false);
	const { showDialog, hideDialog } = useJumboDialog();

	const [educationData, setEducationData] = React.useState({
		qualification: '',
		institute: '',
		candidateId: props?.candidate?.id,
		courseName: '',
		courseBranch: '',
		yearOfJoining: null,
		yearOfPassing: null,
		board: '',
		schoolMedium: '',
		marks: null,
		cgpa: null,
	});
	useEffect(() => {
		getEducation(props.candidate?.id);
	}, []);
	const getEducation = id => {
		Education.getAllEducationByCandidateId(id, response => {
			if (response.status === 'success') {
				setEducation(response?.data);
			} else {
				console.log('error in education');
			}
		});
	};
	// console.log(education,"education");
	// console.log(candidate,"candidate");

	const addEducation = () => {
		Education.addEducation(educationData, response => {
			if (response.status === 'success') {
				Swal.fire({
					icon: 'success',
					title: 'Education has been added successfully',
				});
				const { candidateId } = response?.data;
				if (candidateId) getEducation(candidateId);
			} else {
				Swal.fire({
					icon: 'error',
					title: 'There was an error while adding the education',
				});
			}
		});
		setOpen(false);
	};

	const handleChange = e => {
		setEducationData({ ...educationData, [e.target.name]: e.target.value });
		console.log(educationData);
	};

	const handleEdit = item => {
		setSelectedEducation(item);
		setOpenEdit(true);
	};
	const handleConfirmationDelete = item => {
		showDialog({
			variant: 'confirm',
			title: 'Are you sure about deleting Education?',
			content: "You won't be able to recover this education later",
			onYes: () => handleDelete(item),
			onNo: hideDialog,
		});
	};
	const handleDelete = item => {
		Education.deleteEducation(item.id, response => {
			if (response.status === 'success') {
				GetExperience();
				Swal.fire('Deleted!', 'Education has been deleted.', 'success');
				getEducation(props.candidate?.id);
				hideDialog();
			} else {
				Swal.fire({
					icon: 'error',
					title: 'There is an Error while deleting the Education.',
				});
				hideDialog();
			}
		});
	};

	return (
		<>
			<JumboCardQuick
				demoCode={code}
				wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}
				style={{ marginTop: '10px' }}
			>
				<Div>
					<Box
						display={'flex'}
						justifyContent={'space-between'}
						sx={{ marginTop: '10px', padding: '10px' }}
					>
						<Typography sx={{ fontSize: '20px', fontWeight: '400', marginTop: '10px' }}>
							Qualification
						</Typography>
						<Button
							variant='contained'
							onClick={() => setOpen(true)}
						>
							Add Qualification
						</Button>
					</Box>
					<Dialog
						open={open}
						onClose={() => setOpen(false)}
						maxWidth='md'
					>
						<DialogTitle>Education Details</DialogTitle>
						<form
							onSubmit={e => {
								e.preventDefault();
								addEducation(e);
							}}
						>
							<DialogContent>
								<FormControl
									variant='outlined'
									style={{ marginTop: '10px' }}
									fullWidth
								>
									<InputLabel htmlFor='qualification'>Education</InputLabel>
									<Select
										value={educationData?.qualification}
										onChange={handleChange}
										label='Education'
										inputProps={{
											name: 'qualification',
											id: 'qualification',
										}}
									>
										<MenuItem value='Doctorate/PhD'>Doctorate/PhD</MenuItem>
										<MenuItem value='Masters/Post-Graduation'>Masters/Post-Graduation</MenuItem>
										<MenuItem value='Graduation/Diploma'>Graduation/Diploma</MenuItem>
										<MenuItem value='12th'>12th</MenuItem>
										<MenuItem value='10th'>10th</MenuItem>
										{/* Add more education options as needed */}
									</Select>
								</FormControl>
								{/* ... (other input fields) ... */}
								{educationData?.qualification === '12th' ||
								educationData?.qualification === '10th' ? (
									<>
										<TextField
											margin='dense'
											id='marks'
											label='Marks (% of 100)'
											type='text'
											fullWidth
											name='marks'
											value={educationData.marks}
											onChange={handleChange}
										/>
										<TextField
											margin='dense'
											id='board'
											name='board'
											label='Board'
											type='text'
											fullWidth
											value={educationData.board}
											onChange={handleChange}
										/>
									</>
								) : (
									<>
										<TextField
											margin='dense'
											id='institute'
											label='University/Institute'
											type='text'
											name='institute'
											fullWidth
											value={educationData.institute}
											onChange={handleChange}
										/>
										<TextField
											margin='dense'
											id='courseName'
											label='Course'
											name='courseName'
											type='text'
											fullWidth
											value={educationData?.courseName}
											onChange={handleChange}
										/>
										<TextField
											margin='dense'
											id='courseBranch'
											label='Specialization'
											name='courseBranch'
											type='text'
											fullWidth
											value={educationData?.courseBranch}
											onChange={handleChange}
										/>
										<TextField
											margin='dense'
											id='cgpa'
											label='Percentage/CGPA'
											name='cgpa'
											type='text'
											fullWidth
											value={educationData?.cgpa}
											onChange={handleChange}
										/>{' '}
									</>
								)}

								<Grid
									container
									spacing={2}
								>
									<Grid
										item
										md={6}
									>
										<Typography sx={{ fontSize: '14px' }}>Joining Year</Typography>
										<TextField
											margin='dense'
											id='yearOfJoining'
											name='yearOfJoining'
											type='date'
											fullWidth
											value={educationData?.yearOfJoining}
											onChange={handleChange}
										/>
									</Grid>
									<Grid
										item
										md={6}
									>
										<Typography sx={{ fontSize: '14px' }}>End Year</Typography>
										<TextField
											margin='dense'
											id='yearOfPassing'
											type='date'
											name='yearOfPassing'
											fullWidth
											value={educationData?.yearOfPassing}
											onChange={handleChange}
										/>
									</Grid>
								</Grid>
							</DialogContent>
							<DialogActions>
								<Button
									variant='outlined'
									onClick={() => setOpen(false)}
								>
									Cancel
								</Button>
								<Button
									variant='outlined'
									type='submit'
								>
									Save
								</Button>{' '}
							</DialogActions>
						</form>
					</Dialog>
				</Div>

				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Education</TableCell>
							<TableCell>University/Board</TableCell>
							<TableCell>Course</TableCell>
							<TableCell>Specialization</TableCell>
							<TableCell>CGPA</TableCell>
							<TableCell>Start Date</TableCell>
							<TableCell>End Date</TableCell>
							<TableCell>Action</TableCell>
						</TableRow>
					</TableHead>
					{openEdit ? (
						<EditQualification
							candidate={props.candidate}
							getEducation={getEducation}
							setOpen={setOpenEdit}
							open={openEdit}
							selectedEducation={selectedEducation}
						/>
					) : null}
					{education.map((item, index) => (
						<TableRow key={index}>
							<TableCell>{item?.qualification}</TableCell>
							<TableCell>{item?.institute}</TableCell>
							<TableCell>{item?.courseName}</TableCell>
							<TableCell>{item?.courseBranch}</TableCell>
							<TableCell>{item?.cgpa}</TableCell>
							{/* <TableCell>{item?.courseType}</TableCell> */}
							<TableCell>{item?.yearOfJoining?.split('T')[0]}</TableCell>
							<TableCell>{item?.yearOfPassing?.split('T')[0]}</TableCell>
							<TableCell>
								<Edit
									style={{
										cursor: 'pointer',
										marginRight: '10px',
										marginTop: '10px',
									}}
									onClick={() => handleEdit(item)}
								/>
								<Delete
									style={{ cursor: 'pointer' }}
									onClick={() => handleConfirmationDelete(item)}
								/>
							</TableCell>
						</TableRow>
					))}
				</Table>
			</JumboCardQuick>
		</>
	);
};

export default AddQualification;
