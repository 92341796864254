import appApi from '../../../../src/modules/appApi';
class ResultData {
  getCandidateByBatchId = (batchId, callBack) => {
    return appApi({
      method: 'GET',
      url: `api/candidate_batch/all/batchId/${batchId}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, 'error occurred in getCandidateByBatchId');
      });
  };

  getAssignmentByUserId = (userId, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/user_assignment/all/userId/${userId}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, 'error occurred in getAssignmentByUserId');
      });
  };

  getAssignmentByBatchIdAndUserId = (batchId, userId, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/user_assignment/all/user_assignments/${batchId}/${userId}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, 'error occurred in getAssignmentByBatchIdAndUserId');
      });
  };

  getAssignmentByBatchId = (batchId, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/assignment/batch/' + batchId,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log(error, 'error occurred in getAssignmentByBatchId');
        callBack({ status: 'error' });
      });
  };

  getAttendenceByCandidateId = (candidateId, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/attendence/all/candidateId/' + candidateId,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, 'error occurred in getAttendenceByCandidateId');
      });
  };

  issueCertificate = (id, data, callBack) => {
    return appApi({
      method: 'POST',
      url: '/api/user_certificates/add/' + id,
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, 'Error occurred while Issue Certificate');
      });
  };
}
export default new ResultData();
