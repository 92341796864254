import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import labels from 'modules/labels';
import ContactModule from 'app/mmEsoft/module/ContactModule';
import { useGetAllLabels } from './filterContacts/hooks/useGetAllLabels';
import { LabelList } from 'recharts';

function ContactSearchLabel({ contactList, setContacts, getAllContacts }) {
	const { labelsList, loader } = useGetAllLabels();

	//LABEL PROPS
	const labelProps = {
		options: labelsList,
		getOptionLabel: option => option?.code.split('@')[0],
	};

	// CHIP DATA HANDLING
	const handleLabel = (_, newValue) => {
		if (newValue == null) getAllContacts();
		const filterData = contactList.filter(contacts => {
			if (contacts.labels?.includes(newValue?.code)) return true;
			else return false;
		});
		setContacts(filterData);
	};

	return (
		<>
			<Autocomplete
				onChange={handleLabel}
				name='label'
				startAdornment={
					<InputAdornment position='start'>
						<AccountCircle />
					</InputAdornment>
				}
				{...labelProps}
				id='auto-complete-search'
				autoComplete
				includeInputInList
				renderInput={params => (
					<TextField
						{...params}
						label='Search by label'
						variant='outlined'
						size='small'
					/>
				)}
			/>
		</>
	);
}
export default ContactSearchLabel;
