import React, { useEffect } from 'react';
import {
	Button,
	Box,
	Typography,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
	FormControl,
	Select,
	InputLabel,
	MenuItem,
} from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import Div from '@jumbo/shared/Div';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import Experience from 'app/mmEsoft/module/Experience';
import { Table, TableCell, TableHead, TableRow } from '@mui/material';
import AddExperienceDialog from './AddExperienceDialog';
import EditExperience from './EditExperience';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
const AddExperience = ({ candidate }) => {
	const [open, setOpen] = React.useState(false);
	const [employ, setEmploy] = React.useState([]);
	const [openEdit, setOpenEdit] = React.useState(false);
	const [selectedExperience, setSelectedExperience] = React.useState();
	const { showDialog, hideDialog } = useJumboDialog();

	const Swal = useSwalWrapper();
	useEffect(() => {
		if (candidate) {
			getExperience();
		}
	}, []);
	const [employData, setEmployData] = React.useState({
		companyName: '',
		candidateId: candidate.id,
		position: '',
		description: '',
		startDate: null,
		endDate: null,
		totalExperience: '',
		overallExperience: '',
		employementType: '',
		ctc: '',
		eCtc: '',
		noticePeriod: null,
	});

	const getExperience = () => {
		console.log(candidate.id, 'candidate');
		Experience.getAllExperienceByCandidateId(candidate?.id, response => {
			if (response.status === 'success') {
				setEmploy(response.data);
				//   Swal.fire({
				//     icon: "success",
				//     title: "User has been added successfully.",
				//   });
				// } else {
				//   Swal.fire({
				//     icon: "error",
				//     title: "There is an Error while adding user.",
				//   });
			}
		});
	};
	const handleEdit = e => {
		setSelectedExperience(e);
		setOpenEdit(true);
	};

	const handleConfirmationDelete = item => {
		showDialog({
			variant: 'confirm',
			title: 'Are you sure about deleting Experience?',
			content: "You won't be able to recover this experience later",
			onYes: () => handleDelete(item),
			onNo: hideDialog,
		});
	};
	const handleDelete = item => {
		Experience.deleteEmploy(item.id, response => {
			if (response.status === 'success') {
				getExperience();
				Swal.fire('Deleted!', 'Experience has been deleted.', 'success');
				hideDialog();
			} else {
				Swal.fire({
					icon: 'error',
					title: 'There is an Error while deleting the experience.',
				});
				hideDialog();
			}
		});
	};
	return (
		<>
			<JumboCardQuick
				wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}
				style={{ marginTop: '10px' }}
			>
				<Div>
					<Box
						display={'flex'}
						justifyContent={'space-between'}
						sx={{ marginTop: '10px', padding: '10px' }}
					>
						<Typography sx={{ fontSize: '20px', fontWeight: '400', marginTop: '10px' }}>
							Experience
						</Typography>

						<Button
							variant='contained'
							onClick={() => {
								setOpen(true);
							}}
						>
							Add Experience
						</Button>
					</Box>
					<AddExperienceDialog
						getExperience={getExperience}
						candidate={candidate}
						open={open}
						setOpen={setOpen}
					/>
					{/* experience={selectedExperience} */}
				</Div>
				<Table style={{ marginTop: '20px' }}>
					<TableHead>
						<TableRow>
							<TableCell>Employment Type</TableCell>
							<TableCell>Current Company</TableCell>
							<TableCell>Current Designation</TableCell>
							<TableCell>Skills Used</TableCell>
							<TableCell>Annual Salary</TableCell>
							<TableCell>Expected Salary</TableCell>
							<TableCell>Joining Year</TableCell>
							<TableCell>Notice Period</TableCell>
							<TableCell>Action</TableCell>
						</TableRow>
					</TableHead>
					{openEdit ? (
						<EditExperience
							candidate={candidate}
							getExperience={getExperience}
							setOpen={setOpenEdit}
							open={openEdit}
							selectedExperience={selectedExperience}
						/>
					) : null}

					{employ.map((item, index) => (
						<TableRow key={index}>
							<TableCell>{item?.employementType}</TableCell>
							<TableCell>{item?.companyName}</TableCell>
							<TableCell>{item?.position}</TableCell>
							<TableCell>{item?.description}</TableCell>
							<TableCell>{item?.ctc}</TableCell>
							<TableCell>{item?.eCtc}</TableCell>
							<TableCell>{item?.startDate?.split(' ')[0]}</TableCell>
							<TableCell>{item?.noticePeriod}</TableCell>
							<TableCell>
								<Edit
									style={{
										cursor: 'pointer',
										marginRight: '10px',
										marginTop: '10px',
									}}
									onClick={() => handleEdit(item)}
								/>
								<Delete
									style={{ cursor: 'pointer' }}
									onClick={() => handleConfirmationDelete(item)}
								/>
							</TableCell>
						</TableRow>
					))}
				</Table>
			</JumboCardQuick>
		</>
	);
};
export default AddExperience;
