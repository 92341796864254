import React from 'react';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import JumboDdMenu from '@jumbo/components/JumboDdMenu/JumboDdMenu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GridWithHeader from 'app/CommonComponents/GridWithHeader';
import AddServer from './AddServer';
import emailServer from 'modules/emailServer';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';

function EmailServers() {
  const Swal = useSwalWrapper();
  const [isLoading, setIsLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [emailServerList, setEmailServerList] = React.useState([]);
  const { showDialog, hideDialog } = useJumboDialog();
  const [serverDialog, setServerDialog] = React.useState({
    open: false,
  });
  const setOpenServerDialog = data => {
    setServerDialog({ open: true, data: data });
  };

  const handleCloseDialog = () => {
    setServerDialog({ open: false });
  };

  React.useEffect(() => {
    getAllEmailServers();
  }, []);
  const headers = {
    main: 'Email Servers',
    addButton: 'Add Email Server',
    columns: [
      { name: 'title', label: 'Title' },
      { name: 'host', label: 'Host' },
      { name: 'port', label: 'Port' },
    ],
  };
  const getAllEmailServers = () => {
    emailServer.getAllEmailServers(response => {
      console.log(response.data);
      if (response.status === 'success') {
        setIsLoading(false);
        setEmailServerList(response.data);
      } else {
        setEmailServerList([]);
        setIsLoading(false);
      }
    });
  };
  const addEmailServer = data => {
    serverDialog.data
      ? emailServer.updateEmailServer(serverDialog.data.id, data, response => {
          if (response.status === 'success') {
            getAllEmailServers();

            Swal.fire({
              icon: 'success',
              title: 'Email Server has been updated successfully.',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'There is an Error while updating email server.',
            });
          }
        })
      : emailServer.addEmailServer(data, response => {
          if (response.status === 'success') {
            getAllEmailServers();

            Swal.fire({
              icon: 'success',
              title: 'Email Server has been added successfully.',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'There is an Error while adding email server.',
            });
          }
        });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllEmailServers();
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleItemAction = (actionType, item) => {
    switch (actionType.action) {
      case 'delete':
        showDialog({
          variant: 'confirm',
          title: 'Are you sure about deleting this Email Server?',
          content: "You won't be able to recover this Email Server later",
          onYes: () => handleDelete(item.id),
          onNo: hideDialog,
        });

        break;
      case 'edit':
        handleEdit(item);
        break;
    }
  };
  const handleDelete = id => {
    emailServer.deleteEmailServer(id, response => {
      if (response.status === 'success') {
        getAllEmailServers();
        Swal.fire({
          icon: 'success',
          title: 'Email Server has been deleted successfully.',
        });
        hideDialog();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while deleting Email Server.',
        });
      }
    });
  };
  const handleEdit = data => {
    setServerDialog({ ...serverDialog, open: true, data: data });
  };

  return (
    <>
      <GridWithHeader
        data={emailServerList}
        isLoading={isLoading}
        headers={headers}
        rowsPerPageOptions={[10]}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        actionsColumn={(item, index) => (
          <JumboDdMenu
            menuItems={menuItems}
            onClickCallback={actionType => handleItemAction(actionType, item)}
          />
        )}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        openDialog={() =>
          setServerDialog({ ...serverDialog, open: true, data: '' })
        }
      />
      {serverDialog.open ? (
        <AddServer
          open={serverDialog.open}
          close={handleCloseDialog}
          addEmailServer={addEmailServer}
        />
      ) : null}
      {serverDialog.data && serverDialog.open ? (
        <AddServer
          open={serverDialog.open}
          close={handleCloseDialog}
          addEmailServer={addEmailServer}
          edit={true}
          data={serverDialog.data}
        />
      ) : null}
    </>
  );
}

export default EmailServers;
const menuItems = [
  { icon: <EditIcon />, title: 'Edit', action: 'edit' },
  { icon: <DeleteIcon />, title: 'Delete', action: 'delete' },
];
