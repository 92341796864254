import {
  Box,
  Button,
  Table,
  TableBody,
  TableRow,
  TableContainer,
  TableCell,
  TableHead
} from '@mui/material';

export default function SendEmail() {
  return (
    <Box>
      <TableContainer>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  padding: '0px !important',
                  boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                  borderRadius: '12px',
                  backgroundColor: 'rgb(255, 255, 255)',
                  whiteSpace: 'nowrap',
                }}
              >
                <TableCell>Email</TableCell>
                <TableCell>sent</TableCell>
                <TableCell>Sent At</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                  <TableCell>bhat@gmail.com</TableCell>
                  <TableCell>yes</TableCell>
                  <TableCell>abc</TableCell>
                  <TableCell>
                    <Button disabled>Retry</Button>
                  </TableCell>
                </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
    </Box>
  );
}