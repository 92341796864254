import {
  Stack,
  Paper,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableRow,
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  TableCell,
  ListItemText,
  Button,
  Grid,
  IconButton,
  Typography,
  Chip,
} from '@mui/material';
import { useState, useEffect } from 'react';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder';
import DeleteContact from './deleteContact';
import ContactModule from 'app/mmEsoft/module/ContactModule';
import Checkbox from '@mui/material/Checkbox';
import EditContact from './editContact';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';

function ContactList({
  contactList,
  setContacts,
  getAllContacts,
  setSelectedIds,
  selectedIds,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenuModal, setOpenMenuModal] = useState(false);
  const [deleteMenuModal, setDeleteMenuModal] = useState(false);
  const [deleteTableRowData, setDeleteTableRowData] = useState({}); //send data through states
  const [editTableRowData, setEditTableRowData] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const { searchReslts } = useJumboAuth();
  const { searchTerm } = useJumboAuth();

  const handleOpenMenuModal = (data, event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuModal(true);
    setDeleteTableRowData(data);
    setEditTableRowData(data);
  };

  const handleCloseMenuModal = () => {
    setAnchorEl(null);
    setOpenMenuModal(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteMenuModal(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteMenuModal(false);
  };

  const [selectAll, setSelectAll] = useState(false); // Step 1: State for "Select All" checkbox

  const handleSelectAll = () => {
    setSelectAll(!selectAll); // Step 2: Toggle the "Select All" state
  };
  const handleSelect = id => {
    let updatedSelectedIds = [...selectedIds];

    if (updatedSelectedIds.includes(id)) {
      // If already selected, remove it
      updatedSelectedIds = updatedSelectedIds.filter(
        selectedId => selectedId !== id
      );
    } else {
      // If not selected, add it
      updatedSelectedIds.push(id);
    }

    setSelectedIds(updatedSelectedIds);
    console.log(updatedSelectedIds);
  };

  const [openEdit, setOpenEdit] = useState(false);

  const handleOpenEditModal = () => {
    setOpenEdit(true);
  };

  const handleCloseEditModal = () => {
    setOpenEdit(false);
  };

  // Handle pagination incre
  const pageIncre = () => {
    setPageNumber(pageNumber + 1);
    ContactModule.getAllContacts(pageNumber + 1, 10, response => {
      if (response.status === 'success') setContacts(response?.data);
      else console.log('error while getting the Contact ');
    });
  };

  // Handle pagination decre
  const pageDecre = () => {
    setPageNumber(pageNumber - 1);
    ContactModule.getAllContacts(pageNumber - 1, 10, response => {
      if (response.status === 'success') setContacts(response?.data);
      else console.log('error while getting the Contact ');
    });
  };

  useEffect(() => {
    if (searchReslts.length > 0) {
      setContacts(searchReslts);
    } else if (searchTerm) {
      // If searchReslts is empty and searchTerm is provided, show "No results found"
      setContacts([]);
    }
  }, [searchReslts]);

  return (
    <>
      {/* contact List start */}
      <Grid sx={{ marginTop: 2 }} container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow sx={{ padding: '4px' }}>
                  <TableCell sx={{ padding: '4px' }}>
                    <Checkbox checked={selectAll} onChange={handleSelectAll} />
                  </TableCell>
                  <TableCell> S.no</TableCell>
                  <TableCell> Name </TableCell>
                  <TableCell>Label</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contactList.length <= 0 ? (
                  <JumboListNoDataPlaceholder>
                    <NoDataPlaceholder />
                  </JumboListNoDataPlaceholder>
                ) : (
                  contactList.map((row, index) => {
                    const serialNumber = pageNumber * 10 + index + 1;
                    return (
                      <TableRow
                        key={row.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ padding: '4px' }}
                        >
                          <Checkbox
                            checked={selectAll || selectedIds.includes(row.id)}
                            onChange={() => {
                              handleSelect(row.id);
                            }}
                          />
                        </TableCell>
                        <TableCell>{serialNumber}</TableCell>
                        <TableCell sx={{ padding: '4px' }}>
                          {row.name}
                        </TableCell>
                        <TableCell sx={{ padding: '4px' }}>
                          {row.labels && (
                            <div
                              style={{
                                display: 'flex',
                                maxWidth: '150px', // Set a fixed width for the container
                                overflowX: 'scroll',
                              }}
                            >
                              {row.labels.split(',').map((label, index) => (
                                <Chip
                                  key={index}
                                  label={label.split('@')[0]}
                                  variant="outlined"
                                  style={{ margin: '4px' }}
                                />
                              ))}
                            </div>
                          )}
                        </TableCell>
                        <TableCell sx={{ padding: '4px' }}>
                          {row.email}
                        </TableCell>
                        <TableCell sx={{ padding: '4px' }}>
                          {row?.phoneNumber || row?.phone_number}
                        </TableCell>
                        <TableCell sx={{ padding: '4px' }}>
                          <Button
                            id="basic-button"
                            onClick={event => handleOpenMenuModal(row, event)}
                          >
                            <MoreVertRoundedIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Menu
            anchorEl={anchorEl} // handle postion according to click
            open={openMenuModal}
            onClose={handleCloseMenuModal}
            anchorOrigin={{
              // handle postion according to click
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={handleOpenEditModal}>
              <ListItemIcon>
                <CreateIcon />
              </ListItemIcon>
              <ListItemText primary="Edit" />
            </MenuItem>
            <EditContact
              openEdit={openEdit}
              handleCloseEditModal={handleCloseEditModal}
              handleCloseMenuModal={handleCloseMenuModal}
              editTableRowData={editTableRowData}
              getAllContacts={getAllContacts}
              contactList={contactList}
              pageIncre={pageIncre}
            />
            <MenuItem onClick={() => handleOpenDeleteModal()}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary="Delete" />
            </MenuItem>
            <DeleteContact
              handleCloseMenuModal={handleCloseMenuModal}
              handleCloseDeleteModal={handleCloseDeleteModal}
              deleteMenuModal={deleteMenuModal}
              deleteTableRowData={deleteTableRowData}
              getAllContacts={getAllContacts}
              contactList={contactList}
              pageIncre={pageIncre}
            />
          </Menu>
        </Grid>
      </Grid>
      {/* contact grid end  */}

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: 2, width: '100%' }}
      >
        {pageNumber > 0 ? (
          <IconButton onClick={pageDecre}>
            <NavigateBeforeIcon />
          </IconButton>
        ) : null}
        <Typography
          sx={{
            fontSize: '18px',
            borderRadius: '100px',
          }}
        >
          {pageNumber + 1}
        </Typography>
        {contactList.length < 10 ? null : (
          <IconButton onClick={pageIncre}>
            <NavigateNextIcon />
          </IconButton>
        )}
      </Box>
    </>
  );
}
export default ContactList;
