import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { CheckBox } from '@mui/icons-material';
import {
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
  Typography,
  FormLabel,
  Radio,
  Button,
  Stack,
  Pagination,
  FormGroup,
  Checkbox,
} from '@mui/material';
import campaigns from 'modules/campaigns';
import questions from 'modules/questions';
import userAnswers from 'modules/userAnswers';
import userCertificates from 'modules/userCertificates';
import userProgress from 'modules/userProgress';
import React, { useEffect, useState } from 'react';
import HTMLRenderer from 'react-html-renderer';
import { useSelector } from 'react-redux';

export default function Quiz(props) {
  const Swal = useSwalWrapper();

  const [questionss, setQuestionss] = React.useState([]);
  const [startQuiz, setStartQuiz] = useState(true);
  const [quizCompleted, setQuizCompleted] = React.useState(false);

  const [quizStatus, setQuizStatus] = React.useState({});
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answerData, setAnswerData] = useState();
  const [value, setValue] = React.useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [optionSelected, setOptionSelected] = useState(false);
  const [userProgressData, setUserProgressData] = React.useState([]);
  const { user } = useSelector(({ login }) => login);
  const { myBatch } = useSelector(({ batchesReducer }) => batchesReducer);

  React.useEffect(() => {
    getUserProgressByCampaignId(props?.campaign?.id, props?.userId);
    getAllQuestionsByLessonId(props?.lesson?.lessonId);
    setStartQuiz(true);
  }, [props.lesson?.lessonId]);

  const getAllQuestionsByLessonId = (id) => {
    questions.getAllQuestionsAndOptionsByLesson(id, (response) => {
      if (response.status === 'success') {
        setQuestionss(response.data);
      } else {
        setQuestionss([]);
        console.log('error');
      }
    });
  };

  const handleAnswerOptionClick = (e, isCorrect) => {
    setOptionSelected(true);
    setValue(isCorrect?.text);

    // Update selectedOptions based on whether it's a single or multiple selection
    if (questionss[currentQuestion]?.multipleSelect) {
      setSelectedOptions((prevSelectedOptions) => {
        if (prevSelectedOptions.includes(isCorrect)) {
          // If the option is already selected, remove it
          return prevSelectedOptions.filter((item) => item !== isCorrect);
        } else {
          // If the option is not selected, add it
          return [...prevSelectedOptions, isCorrect];
        }
      });
    } else {
      // For single selection, set selectedOptions as an array with a single element
      setSelectedOptions([isCorrect]);
    }
  };

  useEffect(() => {
    // getUserProgressByCampaignId should be called On Quiz start or After all questions are answered.
    // getUserProgressByCampaignId(props?.campaign?.id, props?.userId);
    getAnswerByUserId(props?.userId);
    if (selectedOptions && selectedOptions.isCorrect) {
      updateProgress();
    }
  }, [selectedOptions]);

  const nextOptionSelect = (p) => {
    getAnswerByUserId(props?.userId);
    handleAddAnswers();
    updateProgress();
    setOptionSelected(false);
    setSelectedOptions([]);
    setCurrentQuestion(p - 1);

    const nextQuestion = currentQuestion + 1;
    let maxQuestionsLength = props.lesson?.maxQuestions;

    if (!maxQuestionsLength) {
      maxQuestionsLength = questionss.length;
    }

    if (questionss.length < props.lesson.maxQuestions) {
      if (nextQuestion < questionss.length) {
        setCurrentQuestion(nextQuestion);
      }
    } else {
      if (nextQuestion < props.lesson.maxQuestions) {
        setCurrentQuestion(nextQuestion);
      }
    }

    if (
      currentQuestion === props.lesson.maxQuestions - 1 ||
      currentQuestion === questionss.length - 1
    ) {
      setQuizCompleted(true);
      getUserProgressByCampaignId(props?.campaign?.id, props?.userId);
    }
  };

  const updateProgress = () => {
    let upData = props?.userProgressData?.find(
      (u) => u.lessonId == props?.lesson?.lessonId
    );

    // const isCorrect = selectedOptions && selectedOptions[0].isAnswer;
    // const isAnswerSubmitted = checkIfAnswerSubmitted();
    const allSelectedOptionsCorrect =
      selectedOptions && selectedOptions.every((option) => option.isAnswer);
    if (upData !== undefined) {
      if (allSelectedOptionsCorrect) {
        upData.quizScore = (upData?.quizScore ? upData?.quizScore : 0) + 1;
        upData.quizStatus = true;
        upData.batchId = myBatch;
        updateUserProgress(upData?.id, upData);
      }
      const noAnswerSubmitted = answerData.length == 0;
      if (noAnswerSubmitted) {
        upData.quizStatus = true;
        upData.batchId = myBatch;
        updateUserProgress(upData?.id, upData);
      }
      const atLeastOneAnswerSubmitted = answerData.length !== 0;
      if (atLeastOneAnswerSubmitted) {
        upData.quizStatus = true;
        upData.batchId = myBatch;
        updateUserProgress(upData?.id, upData);
      }
    }
  };

  const getUserProgressByCampaignId = async (id, userId, lessonData) => {
    userProgress.getAllUserProgressByCampaignId(
      id,
      userId,
      async (response) => {
        if (response.status === 'success') {
          setUserProgressData(response.data);
          const quizesStatus = {};
          if (response.data.length > 0) {
            // Iterate through all lesson IDs in response.data
            response.data.forEach((progress) => {
              const targetLessonId = progress.lessonId;
              const quizStatusForLesson = progress.quizStatus;
              quizesStatus[targetLessonId] = quizStatusForLesson;
            });
            setQuizStatus(quizesStatus);
          }
        }
      }
    );
  };

  const updateUserProgress = (id, data) => {
    userProgress.updateUserProgressById(id, data, (response) => {
      if (response.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'User Progress has been updated successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while updating  user progress.',
        });
      }
    });
  };

  const getCertificateByCampaignId = (id) => {
    campaigns.getCampaign(id, (response) => {
      if (response.status === 'success') {
        addUserCertificate(props.userId, response?.data?.certificateId);
      } else {
        console.log('error');
      }
    });
  };

  const handleQuizStart = () => {
    setStartQuiz(false);
  };

  const handleAddAnswers = () => {
    if (selectedOptions && selectedOptions.length > 0) {
      let data = {
        userId: user?.id,
        // answerId: selectedOptions.id,
        answerId: selectedOptions[0]?.id,
        questionId: questionss[currentQuestion]?.id,
        lessonId: props?.lesson?.lessonId,
      };
      userAnswers.addAnswers(data, (response) => {
        if (
          response.status === 'success' &&
          response.data.status != 'Unique Constraint Error'
        ) {
          Swal.fire({
            icon: 'success',
            title: 'Answers  has been Submitted successfully.',
          });
        } else if (response.data.status == 'Unique Constraint Error') {
          Swal.fire({
            icon: 'error',
            title: 'You have already submitted your answer.',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'There is an Error while Submitting  answer.',
          });
        }
      });
    }
  };

  const getAnswerByUserId = (userId) => {
    userAnswers.getAllAnswersByUserId(userId, (response) => {
      if (response.status === 'success') {
        setAnswerData(response.data);
      } else {
        console.log('err');
      }
    });
  };
  const checkIfAnswerSubmitted = () => {
    const submittedAnswers = answerData || [];
    const currentQuestionAnswerId = selectedOptions && selectedOptions.id;
    const isAnswerSubmitted = submittedAnswers.some(
      (answer) => answer?.id === currentQuestionAnswerId
    );

    return isAnswerSubmitted;
  };

  const addUserCertificate = (userId, certificateId) => {
    let data = {
      userId: userId,
      certificateId: certificateId,
    };
    userCertificates.addUserCertificates(data, (response) => {
      if (response.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'User Certificate has been added successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There was an error while adding the user certificate.',
        });
      }
    });
  };
  const lessonIdToShowScoreFor = props?.lesson?.lessonId;

  // Filter userProgressData to get data for the specific lesson
  const lessonData = userProgressData.find(
    (item) => item.lessonId === lessonIdToShowScoreFor
  );

  // Display the score if the lessonData is found
  const scoreToDisplay = lessonData
    ? lessonData.quizScore
    : 'Score not available';

  return (
    <Grid>
      <>
        <Grid style={{ minHeight: '250px', maxHeight: '350px' }}>
          {quizCompleted || quizStatus[props?.lesson?.lessonId] ? (
            <h2 style={{ marginTop: '10%' }}>
              <center>You have Successfully completed the Quiz</center>
              <center>Your score: {scoreToDisplay}</center>
            </h2>
          ) : startQuiz ? (
            <>
              <h2 style={{ marginTop: '10%' }}>
                <center>Get Ready to Begin the Quiz!</center>
              </h2>
              <p>
                Note: Welcome to the One-Time Quiz Challenge! Get ready to test
                your knowledge and skills. Once you start, make sure to complete
                the quiz in one go, as submissions are allowed only once. Good
                luck!
              </p>
              <center>
                <Button variant='contained' onClick={handleQuizStart}>
                  Start Quiz
                </Button>
              </center>
            </>
          ) : (
            <>
              <Stack spacing={2} display='flex' alignItems='center'>
                <Pagination
                  count={
                    props?.lesson?.maxQuestions > questionss?.length
                      ? questionss?.length
                      : props?.lesson?.maxQuestions
                  }
                  color='secondary'
                  variant='outlined'
                  page={currentQuestion + 1}
                />
              </Stack>

              <FormControl>
                <FormLabel>
                  <Typography variant='h4' textAlign='center'>
                    <HTMLRenderer html={questionss[currentQuestion]?.text} />
                  </Typography>
                </FormLabel>

                {questionss[currentQuestion]?.multipleSelect ? (
                  <FormGroup>
                    {questionss[currentQuestion]?.options?.map(
                      (answerOption) => (
                        <FormControlLabel
                          key={answerOption.text}
                          control={
                            <Checkbox
                              onChange={(e) =>
                                handleAnswerOptionClick(e, answerOption)
                              }
                              name={answerOption.text}
                            />
                          }
                          label={answerOption.text}
                        />
                      )
                    )}
                  </FormGroup>
                ) : (
                  <RadioGroup
                    aria-labelledby='demo-radio-buttons-group-label'
                    value={value}
                    name='radio-buttons-group'
                    // onChange={e => handleAnswerOptionClick(e, answerOption)}

                    onChange={(e) =>
                      handleAnswerOptionClick(
                        e,
                        questionss[currentQuestion]?.options.find(
                          (option) => option.text === e.target.value
                        )
                      )
                    }
                  >
                    {questionss[currentQuestion]?.options?.map(
                      (answerOption) => (
                        <FormControlLabel
                          key={answerOption.text}
                          value={answerOption.text}
                          control={<Radio />}
                          label={answerOption.text}
                        />
                      )
                    )}
                  </RadioGroup>
                )}
              </FormControl>

              <Grid
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  mt: 4,
                }}
              >
                <Button
                  variant='contained'
                  sx={{ mr: 2 }}
                  onClick={() => nextOptionSelect(currentQuestion)}
                  autoFocus
                  disabled={
                    !optionSelected ||
                    selectedOptions.length < 1 ||
                    currentQuestion >= props.lesson.maxQuestions ||
                    currentQuestion === questionss.length
                  }
                >
                  Next
                </Button>
                {/* {(currentQuestion === props.lesson.maxQuestions - 1 ||
                  currentQuestion === questionss.length - 1) && (
                  <Button
                    variant='contained'
                    sx={{ mr: 2 }}
                    onClick={() => {
                      handleQuiz();
                    }}
                    autoFocus
                    disabled={!optionSelected}
                  >
                    Confirm
                  </Button>
                )} */}
              </Grid>
            </>
          )}
        </Grid>
      </>
    </Grid>
  );
}
