import { Button } from '@mui/material';
import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { ChromePicker } from 'react-color';
// import { ChromePicker } from 'react-color';

function hexToRgb(hex) {
  // Remove the hash character if present
  hex = hex.replace(/^#/, '');

  // Parse the hex color code
  const bigint = parseInt(hex, 16);

  // Extract RGB components
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b };
}
function ThemePicker({
  color,
  setColor,
  isMasterDashboardAdmin,
  isMasterDashboard,
}) {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [primaryColor, setPrimaryColor] = useState(
    isMasterDashboardAdmin ? color : '#ffffff'
  ); // Use the appropriate initial value
  const [secondaryColor, setSecondaryColor] = useState(
    isMasterDashboard ? color : '#ffffff'
  );
  const colorPickerRef = useRef();
  const rgbToHex = rgb =>
    `#${((1 << 24) | (rgb.r << 16) | (rgb.g << 8) | rgb.b)
      .toString(16)
      .slice(1)}`;
  const handleColorClick = () => {
    setShowColorPicker(!showColorPicker);
  };

  const handleColorChange = newColor => {
    // Convert hex color string to RGB object
    const rgbColor = hexToRgb(newColor.hex);
    console.log('New Color:', rgbColor);

    // Update the appropriate color state based on the component's type
    if (isMasterDashboardAdmin) {
      setPrimaryColor(rgbColor);
      setColor(rgbColor); // Directly update the color prop
    } else if (isMasterDashboard) {
      setSecondaryColor(rgbColor);
      setColor(rgbColor); // Directly update the color prop
    }
  };

  // Close the color picker when clicking outside of it
  const handleClickOutside = event => {
    if (
      colorPickerRef.current &&
      !colorPickerRef.current.contains(event.target)
    ) {
      setShowColorPicker(false);
    }
  };

  // Attach a click event listener to the document
  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
  // Determine which color to use based on the component's type
  const currentColor = isMasterDashboardAdmin
    ? primaryColor
    : isMasterDashboard
    ? secondaryColor
    : color;

  // Update the color prop when either primary or secondary color changes
  useEffect(() => {
    setColor(currentColor);
  }, [currentColor, setColor]);
  return (
    <div className="color-picker">
      <div className="color-field" onClick={handleColorClick}>
        {isMasterDashboardAdmin && <label>Primary Color</label>}
        {isMasterDashboard && <label>Secondary Color</label>}
        <br />
        <button
          style={{
            width: '80px',
            height: '15px',
            // backgroundColor: color,
            backgroundColor: rgbToHex(
              isMasterDashboardAdmin
                ? primaryColor
                : isMasterDashboard
                ? secondaryColor
                : color
            ),
          }}
          variant="contained"
        ></button>
      </div>
      {showColorPicker && (
        <div ref={colorPickerRef} className="color-picker-popover">
          <ChromePicker
            color={
              isMasterDashboardAdmin
                ? primaryColor
                : isMasterDashboard
                ? secondaryColor
                : color
            }
            onChange={handleColorChange}
          />
        </div>
      )}
    </div>
  );
}

export default ThemePicker;
