import { useState, useEffect } from 'react';
import TabContext from '@mui/lab/TabContext';
import Div from '@jumbo/shared/Div';
import List from '@mui/material/List';
import EditIcon from '@mui/icons-material/Edit';
import {
	Button,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@mui/material';
import BadgeIcon from '@mui/icons-material/Badge';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import styled from '@emotion/styled';
import EditUser from '../EditUser';
import ContactsIcon from '@mui/icons-material/Contacts';
const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
	color: theme.palette.primary.main,
	fontSize: 24,
	height: 48,
	width: 48,
	borderRadius: '50%',
	marginRight: 16,
	padding: theme.spacing(1),
	alignItems: 'center',
	justifyContent: 'center',
	border: `solid 1px ${theme.palette.divider}`,
}));
const About = ({ user }) => {
	console.log(user, "23232");
	const [value, setValue] = useState('1');
	const [openDialog, setOpenDialog] = useState(false);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleClose = () => {};

	return (
		<JumboCardQuick
			title={'Profile'}
			action={
				<TabContext value={value}>
					<Div>
						<EditIcon onClick={() => setOpenDialog(!openDialog)} />
					</Div>
				</TabContext>
			}
			headerSx={{
				borderBottom: 1,
				borderColor: 'divider',
			}}
		>
			<List
				disablePadding
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					margin: theme => theme.spacing(0, -2),
				}}
			>
				<ListItem
					sx={{
						width: { xs: '100%', sm: '50%', xl: '33.33%' },
					}}
				>
					<StyledListItemIcon>
						<BadgeIcon fontSize={'inherit'} />
					</StyledListItemIcon>
					<ListItemText
						primary={<Typography sx={{ fontSize: '16px' }}>Name</Typography>}
						secondary={
							<Typography
								variant='body1'
								color='text.primary'
								mb={2}
							>
								{user?.name}
							</Typography>
						}
					/>
				</ListItem>
				<ListItem
					sx={{
						width: {
							xs: '100%',
							sm: '50%',
							xl: '33.33%',
						},
					}}
				>
					<StyledListItemIcon>
						<CottageOutlinedIcon fontSize={'inherit'} />
					</StyledListItemIcon>
					<ListItemText
						primary={
							<Typography
								fontSize={'16px'}
								variant='h6'
								color='text.primary'
								mb={2}
							>
								{user?.address}
								Address
							</Typography>
						}
						secondary={
							<Typography
								variant='body1'
								color='text.primary'
								mb={2}
							>
								{user?.address}
							</Typography>
						}
					/>
				</ListItem>
				<ListItem
					sx={{
						width: { xs: '100%', sm: '50%', xl: '33.33%' },
					}}
				>
					<StyledListItemIcon>
						<ContactsIcon fontSize={'inherit'} />
					</StyledListItemIcon>
					<ListItemText
						primary={<Typography sx={{ fontSize: '16px' }}>Enrolment No.</Typography>}
						secondary={<Typography sx={{ fontSize: '16px' }}>{user?.id}</Typography>}
					/>
					
				</ListItem>
				
			</List>

			<EditUser
				open={openDialog}
				close={handleClose}
				user={user}
				setOpenDialog={setOpenDialog}
			/>
		</JumboCardQuick>
	);
};

export default About;
