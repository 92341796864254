import {
  Stack,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import ContactList from './contactList';
import AddBulkContact from './addBulkContact';
import AddLabelContact from './addLabelContact';
import ContactSearchLabel from './contactSearchLabel';
import AddContact from './addContact';
import AddFilterDrawerContact from './filterContacts/addFilterDrawerContact';
import React, { useState, useEffect, useRef } from 'react';
import ContactModule from 'app/mmEsoft/module/ContactModule';
import draftToHtml from 'draftjs-to-html';
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'; // Import the CSS for styling
import '../../../pages/questions/ckEditor.css';

import Div from '@jumbo/shared/Div';

import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';

import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useGetAllLabels } from './filterContacts/hooks/useGetAllLabels';
let Editor = () => <React.Fragment />;
function Contacts() {
  const Swal = useSwalWrapper();
  const [contactList, setContacts] = useState([]);
  const [loader, setLoader] = useState(false);
  const [openMail, setOpenMail] = useState(false);
  const contentRef = useRef(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  // GET LABELS
  const { labelsList, getAllLabels } = useGetAllLabels();
  const getAllContacts = () => {
    setLoader(true);
    ContactModule.getAllContacts(0, 10, (response) => {
      if (response.status === 'success') {
        setContacts(response?.data);
        setLoader(false);
      } else {
        console.log('error while getting the Contact ');
        setLoader(false);
      }
    });
  };
  const [selectedIds, setSelectedIds] = useState([]);
  React.useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
    Editor = require('react-draft-wysiwyg').Editor;

    setEditorState(EditorState.createEmpty());
  }, []);

  useEffect(() => {
    getAllContacts();
  }, []);

  const validationSchema = yup.object().shape({
    text: yup.string().required('Email content is required'),
  });
  const handleMail = (data) => {
    ContactModule.sendEmail(data, (response) => {
      if (response.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'Emails sent successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error sending Email',
        });
      }
    });
    setOpenMail(!openMail);
  };
  return (
    <>
      <Grid justifyContent='space-between' container spacing={2}>
        <Grid item xs={12} md={3} lg={3}>
          <ContactSearchLabel
            contactList={contactList}
            setContacts={setContacts}
            getAllContacts={getAllContacts}
          />
        </Grid>
        <Grid item xs={12} md={1} lg={1}>
          <AddLabelContact />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Stack direction='row' alignItems='center' spacing={2}>
            <AddContact getAllContacts={getAllContacts} />
            <AddBulkContact />
          </Stack>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <AddFilterDrawerContact
            labelsList={labelsList}
            getAllLabels={getAllLabels}
            setContacts={setContacts}
          />
        </Grid>
        <Grid item xs={12} md={2} lg={2}>
          <Button
            variant='contained'
            onClick={() => {
              setOpenMail(true);
            }}
          >
            Send Email
          </Button>
          <Dialog
            open={openMail}
            onClose={() => setOpenMail(false)}
            maxWidth='md'
            fullWidth
          >
            <Formik
              validateOnChange={true}
              initialValues={{
                text: '',
              }}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={(data) => {
                console.log(data, selectedIds, 'form data');
                data.ids = selectedIds;
                setOpenMail(false); // Close the dialog after submitting
                handleMail(data);
              }}
            >
              {({ setFieldValue }) => (
                <Form
                  style={{ textAlign: 'left' }}
                  noValidate
                  autoComplete='off'
                >
                  <DialogTitle>Send Email</DialogTitle>
                  <DialogContent>
                    <Box sx={{ width: '700px' }}>
                      <Div>
                        <JumboTextField
                          sx={{ mt: 2, width: '700px', mb: 2 }}
                          name='subject'
                          label='Subject'
                        />
                      </Div>
                      <div>
                        <Typography> Email</Typography>
                        <Editor
                          editorStyle={{
                            width: '100%',
                            minHeight: 100,
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: 'lightgray',
                          }}
                          editorState={editorState}
                          onEditorStateChange={(editorState) => {
                            setEditorState(editorState);
                            let text = draftToHtml(
                              convertToRaw(editorState.getCurrentContent())
                            );
                            setFieldValue('text', text);
                          }}
                        />
                      </div>
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button size='small' variant='contained' type='submit'>
                      Send Email
                    </Button>
                    <Button
                      size='small'
                      variant='outlined'
                      onClick={() => setOpenMail(false)}
                      sx={{ mr: 2 }}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </Dialog>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          {loader ? (
            <div className='loader' style={{ marginTop: '20%' }}>
              <svg className='circular-loader' viewBox='25 25 50 50'>
                <circle className='loader-path' cx='50' cy='50' r='20' />
              </svg>
            </div>
          ) : (
            <ContactList
              contactList={contactList}
              setContacts={setContacts}
              getAllContacts={getAllContacts}
              setSelectedIds={setSelectedIds}
              selectedIds={selectedIds}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Contacts;
