import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Div from "@jumbo/shared/Div";
import ExamDetails from "./ExamDetails";
import AddExam from "./AddExams";
import exams from "modules/exams";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import JumboListNoDataPlaceholder from "@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder";
import NoDataPlaceholder from "app/shared/NoDataPlaceholder";

const Exams = () => {
  const Swal = useSwalWrapper();
  const [expanded, setExpanded] = React.useState(false);
  const [openExamDialog, setOpenExamDialog] = React.useState(false);
  const [examData, setExamData] = React.useState([]);
  const [isLoading, setIsloading] = React.useState(true);
  const{ searchReslts}= useJumboAuth();
  const{ searchTerm}= useJumboAuth();
  React.useEffect(() => {
    if(searchReslts.length>0){
      setExamData(searchReslts);
    }else if (searchTerm) {
      // If searchReslts is empty and searchTerm is provided, show "No results found"
      setExamData([]);
    }else{
    getAllExams();
    }
  }, [searchReslts]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleCloseDialog = () => {
    setOpenExamDialog(false);
  };
  const addExam = (data) => {
    exams.addExam(data, (response) => {
      if (response.status === "success") {
        getAllExams();
        setIsloading(false);
        Swal.fire({
          icon: "success",
          title: "Exam has been added successfully.",
        });
      } else {
        setIsloading(false);
        Swal.fire({
          icon: "error",
          title: "There is an Error while adding exam.",
        });
      }
    });
  };
  const getAllExams = () => {
    exams.getAllExams((response) => {
      if (response.status === "success") {
        console.log(response.data);
        setIsloading(false);
        setExamData(response.data);
      } else {
        setExamData([]);
        setIsloading(false);
      }
    });
  };

  return (
    <Div>
      {isLoading ? (
        <div className="loader">
          <svg className="circular-loader" viewBox="25 25 50 50">
            <circle className="loader-path" cx="50" cy="50" r="20" />
          </svg>
        </div>
      ) : examData?.length <= 0 && !isLoading ? (
        <JumboListNoDataPlaceholder>
          <NoDataPlaceholder />
        </JumboListNoDataPlaceholder>
      ) : (
        <>
          <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
            <Typography variant={"h2"}>Exams</Typography>
            <Button variant="contained" onClick={() => setOpenExamDialog(true)}>
              Add Exam
            </Button>
          </Box>

          {examData.map((exam, index) => (
            <Card sx={{ mb: 1 }}>
              <Accordion
                expanded={expanded === "panel" + index}
                onChange={handleChange("panel" + index)}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === "panel" + index ? <RemoveIcon /> : <AddIcon />
                  }
                  // expandIcon={<ExpandMoreIcon />}
                  // aria-controls="panel1bh-content"
                  // id="panel1bh-header"
                >
                  {exam.title}
                </AccordionSummary>
                <AccordionDetails>
                  <ExamDetails
                    exam={exam}
                    setExpanded={setExpanded}
                    getAllExams={getAllExams}
                  />
                </AccordionDetails>
              </Accordion>
            </Card>
          ))}

          <AddExam
            open={openExamDialog}
            close={handleCloseDialog}
            addExam={addExam}
          />
        </>
      )}
    </Div>
  );
};

export default Exams;
