import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Div from '@jumbo/shared/Div';
import { alpha } from '@mui/material/styles';
import bgImg from '../../../../Assets/esoft img (1).jpg';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import resetPassword from 'modules/resetPassword';
import logo from '../../../../Assets/logo-esoft.png';
import Link from '@mui/material/Link';

import { useLocation } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Logo from 'app/shared/Logo';

const ResetPassword = () => {
  console.log('reset password');
  const Swal = useSwalWrapper();
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [confirmPasswordError, setConfirmPasswordError] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  let [buttonClick, setButtonClick] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('token');
  const handleChange = e => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    if (newPassword.length < 6) {
      setPasswordError('Password must be at least 6 characters');
    } else if (!/[a-z]/i.test(newPassword)) {
      setPasswordError('Password must contain at least one letter');
    } else if (!/\d/.test(newPassword)) {
      setPasswordError('Password must contain at least one digit');
    } else if (!/[@$!%*?&]/.test(newPassword)) {
      setPasswordError('Password must contain at least one special character');
    } else {
      setPasswordError('');
    }
  };

  const handleConfirmPasswordChange = e => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);

    if (newConfirmPassword !== password) {
      setConfirmPasswordError('Passwords do not match');
    } else {
      setConfirmPasswordError('');
    }
  };
  // const handleReset = (event) => {
  //   setResetCode(event.target.value);
  // };

  const handleSubmit = () => {
    if (!passwordError && !confirmPasswordError) {
      const data = {
        // Include the reset code here
        newPassword: password,
        token: code,
      };
      if (data.token == '' || data.newPassword == '') {
        return;
      }
      resetPassword.sendData(data, response => {
        if (response.status === 'success') {
          Swal.fire({
            icon: 'success',
            title: 'Password reset successfully',
          });
          navigate('/user/login');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error while resetting password',
          });
        }
        setButtonClick(false);
      });
    }
  };
  // for password toggling
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <Div sx={{ width: 720, maxWidth: '100%', margin: 'auto', p: 4 }}>
      <Card
        sx={{
          display: 'flex',
          minWidth: 0,
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <CardContent
          sx={{
            flex: '0 1 300px',
            position: 'relative',
            backgroundImage: `url("${bgImg}") !important; `,
            backgroundSize: 'cover',
            backgroundPosition: 'right',
            backgroundRepeat: 'no-repeat',

            '&::after': {
              display: 'inline-block',
              position: 'absolute',
              content: `''`,
              inset: 0,
              backgroundColor: alpha('#0267a0', 0.65),
            },
          }}
        >
          <Div
            sx={{
              display: 'flex',
              minWidth: 0,
              flex: 1,
              flexDirection: 'column',
              color: 'common.white',
              position: 'relative',
              zIndex: 1,
              height: '100%',
              minHeight: { md: 320 },
            }}
          >
            <Div sx={{ mb: 2 }}>
              <Typography
                variant={'h3'}
                color={'inherit'}
                fontWeight={500}
                mb={3}
              >
                Reset Password
              </Typography>
              <Typography variant={'body1'} mb={1} sx={{ maxWidth: 270 }}>
                By entering your registered email address you will receive reset
                password link, Kindly follow instruction.
              </Typography>
            </Div>

            <Div sx={{ mt: 'auto', position: 'relative', bottom: '-20px' }}>
              <Logo />
              {/* <Link href="#" underline="none" sx={{ display: 'inline-flex' }}>
                <img
                  style={{
                    width: 'auto',
                    height: '40px',
                  }}
                  src={logo}
                  alt="Jumbo React"
                />
                <h2
                  style={{
                    fontSize: '30px',
                    color: 'white',
                    position: 'relative',
                    top: '-30px',
                    fontWeight: '600',
                  }}
                >
                  oft
                </h2>
              </Link> */}
            </Div>
          </Div>
        </CardContent>
        <CardContent sx={{ flex: 1, p: 4 }}>
          <Div
            sx={{
              display: 'flex',
              minWidth: 0,
              flex: 1,
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Div sx={{ mb: 2 }}>
              <Div sx={{ mt: 1, mb: 3 }}>
                <TextField
                  sx={{ mt: 1, mb: 3 }}
                  fullWidth
                  id="password"
                  label="Enter New Password"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={password}
                  onChange={handleChange}
                  error={!!passwordError}
                  helperText={passwordError}
                />
                <TextField
                  sx={{ mt: 1, mb: 3 }}
                  fullWidth
                  id="confirmPassword"
                  label="Confirm New Password"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  error={!!confirmPasswordError}
                  helperText={confirmPasswordError}
                />
              </Div>
              <Button
                variant="contained"
                onClick={() => {
                  handleSubmit();
                  setButtonClick(true);
                }}
              >
                Submit
              </Button>
            </Div>
            {/* <Typography variant={"body1"} mt={'auto'}>
                            Already have an account? <Link href={"#/"} underline={'none'}>Login Now</Link> Or <Link
                            href={"#/"} underline={'none'}>Create New</Link>
                        </Typography> */}
          </Div>
        </CardContent>
      </Card>
    </Div>
  );
};

export default ResetPassword;
