import { useState, useEffect } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';

import {
  Button,
  Box,
  IconButton,
  ButtonGroup,
  FormControl,
  InputLabel,
  Select,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Accordion,
  AccordionDetails,
  Paper,
  AccordionSummary,
} from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GetAppIcon from '@mui/icons-material/GetApp';
import UserAssigments from 'app/mmEsoft/module/UserAssigments';
import Assignment from 'app/mmEsoft/module/Assignment';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import Batches from 'app/mmEsoft/module/Batches';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { t } from 'i18next';
import moment from 'moment';
function ViewAssignment() {
  const [userAssignmentList, setUserAssignmentList] = useState([]);
  const [batch, setBatch] = useState();
  const [assignment, setAssignment] = useState();
  const [expanded, setExpanded] = useState(false); //Accordain
  const [batches, setBatches] = useState('');
  const [allBatches, setAllBatches] = useState([]);
  const [assignmentOfUser, setAssignmentOfUser] = useState([]);
  const [userAsId, setUserAsgntId] = useState(null);
  const [assignmentsDetailsLoader, setAssignmentDetailsLoader] =
    useState(false);
  const [formData, setFormData] = useState({
    score: '',
    feedback: '',
  });

  const Swal = useSwalWrapper();

  const handleChange = (panel, items) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);

    setUserAsgntId(items?.id);
  };

  const handleOnChangeUpdateAssignments = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const getAssignmentByAssignmentId = () => {
    UserAssigments.getAssignmentByAssignmentId(assignmentId, (response) => {
      if (response.status === 'success') {
        setUserAssignmentList(response?.data);
      } else {
        console.log('error while getting the user Assignments List ');
      }
    });
  };

  const handleSubmitUpdatedAssignments = (assignmentId, userId) => {
    let data = formData;

    UserAssigments.updateUserAssignments(
      assignmentId,
      userId,
      data,
      (response) => {
        if (response.status === 'success') {
          Swal.fire({
            icon: 'success',
            title: 'Fields has been updated successfully.',
          });

          handleAllAssignment();
          // Update the userAssignmentList state with the updated score
          const updatedList = userAssignmentList.map((item) => {
            if (item.assignmentId === assignmentId) {
              return { ...item, score: formData.score };
            }
            return item;
          });
          // setUserAssignmentList(updatedList);
          getAssignmentByAssignmentId();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'There is an Error while updating Assignments.',
          });
        }
      }
    );
  };

  // Fetching all batches on component mount
  // useEffect(() => {
  //   getAllBatches();
  // }, []);

  // Function to fetch all batches
  // const getAllBatches = () => {
  //   Batches.getAllBatches((response) => {
  //     if (response.status === "success") {
  //       setAllBatches(response?.data);
  //       setBatch(response?.data[0].id);
  //     } else {
  //       console.log("error while getting the All Batches  ");
  //     }
  //   });
  // };

  // Event handler for fetching assignments based on the selected batch
  const handleAllAssignment = () => {
    setAssignmentDetailsLoader(true);
    UserAssigments.getAssignmentByBatchId(batchId, 0, 10, (response) => {
      if (response.status === 'success') {
        setAssignmentOfUser(response?.data);
        setAssignment(response.data[0]?.id);
        setAssignmentDetailsLoader(false);
        // getAssignmentByAssignmentId(response.data[0].id);  //this way we send id of array of object index wise
      } else {
        console.log('error while getting the user  Assignments ');
        setAssignmentDetailsLoader(false);
      }
    });
  };

  // Fetch assignments when the selected batch changes
  useEffect(() => {
    getAssignmentByAssignmentId();
    handleAllAssignment();
  }, [batch]);

  // Extracting assignmentId from the location state
  const [userAssignmentId, setUserAssignmentId] = useState(null);
  // const location = useLocation();
  // const assignmentId = location.state?.assignmentId;

  // useEffect(() => {
  //   if (assignmentId) {
  //     console.log(assignmentId, "Assigmnet id ");
  //     console.log( assignmentOfUser.length > 0 ? assignmentOfUser : "Assignmnet length 0"
  //     );
  //     let findAssignmentId = assignmentOfUser.find(
  //       (item) => item?.id === assignmentId
  //     );
  //     console.log(assignmentOfUser, "assignmnet ");
  //     setUserAssignmentId(findAssignmentId);
  //     console.log(findAssignmentId, "find assignmnet id");
  //     console.log(userAssignmentId, "userAssignmentId");
  //   } else {
  //     console.error("Error: assignmentId is undefined");
  //   }
  // }, [assignmentId, assignmentOfUser]);

  const location = useLocation();
  const { assignmentId, batchId } = location.state || {};

  useEffect(() => {
    if (assignmentId) {
      let findAssignmentId = assignmentOfUser.find(
        (item) => item?.id === assignmentId
      );
      setUserAssignmentId(findAssignmentId);
    } else {
      console.error('Error: assignmentId is undefined');
    }
  }, [assignmentId, assignmentOfUser, batchId]);

  const downloadAssignmentByAssignmentId = (items) => {
    UserAssigments.downloadGivenAssignmentByAssignmentId(
      userAsId,
      (response) => {
        if (response.status === 'success') {
          const downloadFile = response.data;
          const url = downloadFile.url;
          const a = document.createElement('a');
          a.href = url;
          a.setAttribute('target', '_blank');
          a.click();
          a.remove();
        } else {
          console.log('Error while downloading the user Assignments');
        }
      }
    );
  };

  //TODO download assignment of Admin Done
  const handledownloadAdminGivenAssignmentById = () => {
    UserAssigments.downloadAssignmentByAssignmentId(
      assignmentId,
      userAssignmentId?.file,
      (response) => {
        if (response.status === 'success') {
          const downloadFile = response.data;
          const url = downloadFile.url;
          const a = document.createElement('a');
          a.href = url;
          a.setAttribute('target', '_blank');
          a.click();
          a.remove();
        } else {
          console.log('Error while downloading the Admin Given  Assignment');
        }
      }
    );
  };

  return (
    <>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='flex-start'
        spacing={2}
      >
        <Box>
          <ButtonGroup aria-label='outlined primary button group'>
            <Link to='/assignment/addAssignment'>
              <Button
                startIcon={<KeyboardBackspaceIcon />}
                style={{ padding: '8px' }}
              >
                Assignment
              </Button>
            </Link>
          </ButtonGroup>
        </Box>
      </Stack>

      {assignmentsDetailsLoader ? (
        <div className='loader' style={{ marginTop: '20%' }}>
          <svg className='circular-loader' viewBox='25 25 50 50'>
            <circle className='loader-path' cx='50' cy='50' r='20' />
          </svg>
        </div>
      ) : (
        <Box style={{ marginTop: '20px' }}>
          <Stack
            direction='row'
            justifyContent=''
            alignItems='flex-start'
            spacing={25}
          >
            <Typography variant='h1'>{userAssignmentId?.title}</Typography>
            <Button
              variant='outlined'
              startIcon={<ArrowDownwardIcon />}
              onClick={handledownloadAdminGivenAssignmentById}
            >
              Download
            </Button>
          </Stack>
          <Typography variant='body1'>
            {' '}
            {userAssignmentId?.description}
          </Typography>
          <Stack
            direction='row'
            justifyContent=''
            alignItems='flex-start'
            spacing={25}
            style={{
              backgroundColor: '#fff',
              marginTop: '10px',
              padding: '10px',
            }}
          >
            <Box>
              <p>
                <Typography variant='h5'>Start Date </Typography>
                <Typography variant='body1'>
                  {userAssignmentId?.startDate}
                </Typography>
              </p>
              <p>
                <Typography variant='h5'>File Type </Typography>
                <Typography variant='body1'>
                  {userAssignmentId?.file}
                </Typography>
              </p>
            </Box>

            <Box>
              <p>
                <Typography variant='h5'>End Date </Typography>
                <Typography variant='body1'>
                  {userAssignmentId?.endDate}
                </Typography>
              </p>
              <p>
                <Typography variant='h5'>maximumMarks </Typography>
                <Typography variant='body1'>
                  {userAssignmentId?.maximumMarks}
                </Typography>
              </p>
            </Box>
          </Stack>
        </Box>
      )}

      {/* Accordain start */}
      {userAssignmentList.map((items, index) => {
        return (
          <>
            <Accordion
              sx={{ marginTop: '10px' }}
              expanded={expanded === `panel${index}`} // Use a unique identifier for each panel
              onChange={handleChange(`panel${index}`, items)} // Use a unique identifier for each panel
              // onClick={handleClick(items)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1bh-content'
                id='panel1bh-header'
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  {index + 1}
                  {'  '}
                  {items.name}
                </Typography>
                <Typography
                  sx={{
                    width: '33%',
                    flexShrink: 0,
                    color: 'text.secondary',
                  }}
                >
                  {/* Submitted on {(items.createdAt)} */}
                  {items.isSubmitted
                    ? moment(items.createdAt).format('MMMM Do YYYY, h:mm:ss a')
                    : 'not submitted'}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  Result {items.score}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography varient='h3'>Answer </Typography>
                <Typography varient='body1' sx={{ mb: 4 }}>
                  {items.answer}
                </Typography>
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='flex-start'
                  spacing={2}
                >
                  <Button
                    variant='contained'
                    endIcon={<GetAppIcon />}
                    sx={{ mb: 2 }}
                    onClick={downloadAssignmentByAssignmentId}
                  >
                    {' '}
                    Download File
                  </Button>

                  <TextField
                    id='outlined-basic'
                    variant='outlined'
                    type='number'
                    name='score'
                    value={formData.score}
                    // disabled={isExpired}
                    placeholder='Scores '
                    onChange={handleOnChangeUpdateAssignments}
                    style={{ width: '100px', height: '0', padding: '5px' }}
                  />
                </Stack>

                <Typography varient='h5' sx={{ mt: 2 }}>
                  Remarks:
                </Typography>
                <TextField
                  id='feedback'
                  label=''
                  multiline
                  name='feedback'
                  row={3}
                  variant='outlined'
                  fullWidth
                  value={formData.feedback}
                  onChange={handleOnChangeUpdateAssignments}
                />
                <Button
                  variant='contained'
                  fullWidth
                  sx={{ mt: 2 }}
                  onClick={() => {
                    setExpanded(false);
                    // Add this line to close the accordion
                    handleSubmitUpdatedAssignments(
                      items.assignmentId,
                      items.user_id
                    );
                  }}
                >
                  Save
                </Button>
              </AccordionDetails>
            </Accordion>
          </>
        );
      })}

      {/* accordain end */}
    </>
  );
}

export default ViewAssignment;
