import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder';
import Div from '@jumbo/shared/Div';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder';
import license from 'modules/license';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import LicenseCell from '../licenses/LicenseCell';
import AddLicense from '../licenses/AddLicense';
import ThemePicker from '../auth-pages/ThemePicker';
import company from 'modules/company';
import { Paper } from '@mui/material';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import { PhotoCamera } from '@mui/icons-material';
import { appServerURL, logoUrl } from 'modules/appApi';
import ColorPicker from '../certificates/ColorPicker';
import DzDisabled from '../extensions/dropzone/components/DzDisabled';
import DzStyled from '../extensions/dropzone/components/DzStyled';
import DzPreviews from '../extensions/dropzone/components/DzPreviews';
import Logo from 'app/shared/Logo';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';

const MasterAdminDashboard = ({ disableSmLogin }) => {
  const [licenseDialog, setLicenseDialog] = React.useState({
    open: false,
    data: {
      licenseName: '',
      assignedLisences: '',
      purchasedQuantity: '',
      billingFrequency: '',
      renewsOn: '',
      // expired:false,
      plan: '',
    },
  });

  const [logo, setLogo] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [openAddMultiple, setOpenAddMultiple] = React.useState(false);
  const [totalCount, setTotalCount] = React.useState(0);
  const [licenseData, setLicenseData] = useState([]);
  const [licenseList, setLicenseList] = React.useState([]);
  const [isLoading, setIsloading] = useState(true);
  const Swal = useSwalWrapper();
  const closeDialog = () => {
    setLicenseDialog({ ...licenseDialog, open: false });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const openDialog = () => {
    setLicenseDialog({ ...licenseDialog, open: true });
  };

  React.useEffect(() => {
    getAllLicenses();
  }, []);
  const addLicense = data => {
    license.addLicense(data, response => {
      if (response.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'Lesson has been added successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while adding license.',
        });
      }
    });
  };

  const getAllLicenses = () => {
    license.getAllLicenses(response => {
      if (response.status === 'success') {
        setLicenseList(response.data);
        setIsloading(false);
      } else {
        setLicenseList([]);
        setIsloading(false);
      }
    });
  };

  const getAllLicenseByCompany = id => {
    license.getAllLicenseByCompany(id, response => {
      if (response.status === 'success') {
        console.log(response.data);
        setLicenseData(response.data);
        setIsloading(false);
      } else {
        setIsloading(false);
        setLicenseData([]);
      }
    });
  };
  const [openAddMultipleDialog, setOpenAddMultipleDialog] = useState(false);
  const handleMultipleDialogClose = () => {
    setOpenAddMultipleDialog(false);
  };

  const [isDialogOpen, setDialogOpen] = useState(false);

  const openThemeDialog = () => {
    setDialogOpen(true);
  };

  const closeThemeDialog = () => {
    setDialogOpen(false);
  };
  useEffect(() => {
    document.fonts.ready.then(function () {
      // Get the list of available fonts
      var fontNames = [
        'Arial',
        'Arial Black',
        'Bahnschrift',
        'Calibri',
        'Cambria',
        'Cambria Math',
        'Candara',
        'Comic Sans MS',
        'Consolas',
        'Constantia',
        'Corbel',
        'Courier New',
        'Ebrima',
        'Franklin Gothic Medium',
        'Gabriola',
        'Gadugi',
        'Georgia',
        'HoloLens MDL2 Assets',
        'Impact',
        'Ink Free',
        'Javanese Text',
        'Leelawadee UI',
        'Lucida Console',
        'Lucida Sans Unicode',
        'Malgun Gothic',
        'Marlett',
        'Microsoft Himalaya',
        'Microsoft JhengHei',
        'Microsoft New Tai Lue',
        'Microsoft PhagsPa',
        'Microsoft Sans Serif',
        'Microsoft Tai Le',
        'Microsoft YaHei',
        'Microsoft Yi Baiti',
        'MingLiU-ExtB',
        'Mongolian Baiti',
        'MS Gothic',
        'MV Boli',
        'Myanmar Text',
        'Nirmala UI',
        'Palatino Linotype',
        'Segoe MDL2 Assets',
        'Segoe Print',
        'Segoe Script',
        'Segoe UI',
        'Segoe UI Historic',
        'Segoe UI Emoji',
        'Segoe UI Symbol',
        'SimSun',
        'Sitka',
        'Sylfaen',
        'Symbol',
        'Tahoma',
        'Times New Roman',
        'Trebuchet MS',
        'Verdana',
        'Webdings',
        'Wingdings',
        'Yu Gothic',
      ];

      setFontList(fontNames);
    });
  }, []);

  const [fontSize, setFontSize] = useState('14px');
  const [fontFamily, setFontFamily] = useState('Open Sans');
  const [fontList, setFontList] = useState([]);

  const [fontColor, setFontColor] = useState('#C0C0C0');
  const [fontColors, setFontColors] = useState('#C0C0C0');
  const [otherColor, setOtherColor] = useState('#C0C0C0');

  useEffect(() => {
    const storedTheme = JSON.parse(localStorage.getItem('themeSettings')) || {};
    if (storedTheme) {
      setFontColor(storedTheme.primary || '#C0C0C0');
      setOtherColor(storedTheme.secondary || '#C0C0C0');
      setFontColors(storedTheme.fontStyle?.fontColor || '#C0C0C0');
      setFontFamily(storedTheme.fontStyle?.fontFamily || 'Open Sans');
      setFontSize(storedTheme.fontStyle?.fontSize || '14px');
    }
  }, []);
  const sendDataToBackend = () => {
    const themeData = {
      primary: {
        r: fontColor.rgb ? fontColor.rgb.r : fontColor.r,
        g: fontColor.rgb ? fontColor.rgb.g : fontColor.g,
        b: fontColor.rgb ? fontColor.rgb.b : fontColor.b,
      },
      secondary: {
        r: otherColor.rgb ? otherColor.rgb.r : otherColor.r,
        g: otherColor.rgb ? otherColor.rgb.g : otherColor.g,
        b: otherColor.rgb ? otherColor.rgb.b : otherColor.b,
      },
      fontStyle: {
        fontSize: fontSize,
        fontColor: fontColors,
        fontFamily: fontFamily,
      },
    };

    const inputData = {
      theme: JSON.stringify(themeData),
    };
    //  Make the API call to save the theme
    company.addOrUpdateCompanyTheme(inputData, response => {
      if (response.status === 'success') {
        localStorage.setItem('themeSettings', JSON.stringify(themeData));

        // Log the stored data
        console.log(
          'Theme data stored in localStorage:',
          localStorage.getItem('themeSettings')
        );
        Swal.fire({
          icon: 'success',
          title: 'Theme has been saved successfully.',
        });
        closeThemeDialog();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error while saving the Theme',
        });
      }
    });
  };

  const handleGetLogo = () => {
    company.getLogo(response => {
      if (response.status === 'success') {
        setLogo(response.data);
      } else {
        console.error('Failed to get logo');
      }
    });
  };
  useEffect(() => {
    handleGetLogo();
  }, []);

  const addOrUpdateLogo = file => {
    const data = new FormData();
    data.append('file', file);
    company.addOrUpdateLogo(data, response => {
      console.log('API Response:', response);
      if (response.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'Logo has been uploaded successfully.',
        });
        // setLogo(data);
        handleGetLogo();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while uploading logo.',
        });
      }
    });
  };
  const handleFile = files => {
    let file = files[0];
    addOrUpdateLogo(file);
  };

  const updateLabelStyles = () => {
    const labelElements = document.querySelectorAll('.draggable-label');
    labelElements.forEach(label => {
      label.style.fontSize = fontSize;
      label.style.color = fontColor;
      label.style.fontFamily = fontFamily;
    });
  };
  const handleChange = e => {
    setFontFamily(e.target.value);
    updateLabelStyles();
  };
  const handleFontSizeChange = event => {
    setFontSize(event.target.value);
    updateLabelStyles();
  };

  return (
    <React.Fragment>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant={'h2'} mt={1}>
          Licenses
        </Typography>
        <Box>
          <Button variant="contained" onClick={() => openDialog()}>
            Add Licenses
          </Button>
          <Button
            variant="contained"
            onClick={openThemeDialog}
            sx={{ marginLeft: '8px' }}
          >
            Add Theme
          </Button>
          <div>
            <Dialog
              open={isDialogOpen}
              onClose={closeThemeDialog}
              fullWidth
              maxWidth="md"
            >
              <DialogTitle>Add Theme</DialogTitle>

              <DialogContent
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div>
                  {logo ? (
                    <img
                      src={logoUrl}
                      alt="Logo"
                      style={{ width: '150px', height: '40px' }}
                    />
                  ) : (
                    <img
                      src={logoUrl}
                      alt="Logo"
                      style={{ width: '150px', height: '40px' }}
                    />
                  )}
                  <Box
                    mb={2}
                    style={{
                      width: '100%',
                      maxWidth: '400px',
                      alignSelf: 'center',
                    }}
                  >
                    {/* <Button
                  variant="contained"
                  color="primary"
                  style={{ minWidth: '150px', marginBottom: '38px' }}
                >
                  <input
                    accept="image/*"
                    type="file"
                    onChange={handleFile}
                  />
                </Button> */}
                    <DzPreviews type="image/*" handleFiles={handleFile} />
                  </Box>
                </div>

                {/* First ThemePicker on the left */}
                <Box
                  mb={2}
                  style={{
                    width: '100%',
                    maxWidth: '400px',
                    alignSelf: 'center',
                  }}
                >
                  <Paper
                    elevation={3}
                    style={{ borderRadius: 12, padding: 16 }}
                  >
                    <ThemePicker
                      color={fontColor}
                      setColor={newColor => setFontColor(newColor)}
                      isMasterDashboardAdmin={true}
                    />
                  </Paper>
                </Box>

                {/* Second ThemePicker on the right */}
                <Box
                  mb={2}
                  style={{
                    width: '100%',
                    maxWidth: '400px',
                    alignSelf: 'center',
                  }}
                >
                  <Paper
                    elevation={3}
                    style={{ borderRadius: 12, padding: 16 }}
                  >
                    <ThemePicker
                      color={otherColor}
                      setColor={newColor => setOtherColor(newColor)}
                      isMasterDashboard={true}
                    />
                  </Paper>
                </Box>

                <Grid
                  item
                  xs={4}
                  md={1.5}
                  sm={8}
                  sx={{
                    mb: 2,
                    mt: 1,
                    width: '100%',
                    maxWidth: '400px',
                    alignSelf: 'center',
                  }}
                >
                  <Paper
                    elevation={3}
                    style={{ borderRadius: 12, padding: 16 }}
                  >
                    {/* Your ColorPicker component */}
                    <ColorPicker color={fontColors} setColor={setFontColors} />

                    {/* Your font select component */}
                    <div style={{ marginTop: '16px' }}>
                      <label htmlFor="fontSelect">Select Font</label>
                      <br />
                      <select
                        style={{ width: '22%' }}
                        id="fontSelect"
                        onChange={handleChange}
                        value={fontFamily}
                      >
                        {fontList.map((font, index) => (
                          <option key={index} value={font}>
                            {font}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Your size select component */}
                    <div style={{ marginTop: '16px' }}>
                      <label htmlFor="sizeSelect">Select Size</label>
                      <br />
                      <select
                        style={{ width: '22%' }}
                        id="sizeSelect"
                        onChange={handleFontSizeChange}
                        value={fontSize}
                      >
                        {Array.from({ length: 17 }, (_, index) => (
                          <option key={index} value={`${index + 6}px`}>
                            {`${index + 6}px`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Paper>
                </Grid>
              </DialogContent>

              <DialogActions>
                {/* Cancel button for both ThemePickers */}
                <Button onClick={closeThemeDialog} color="primary">
                  Cancel
                </Button>
                {/* Save button for both ThemePickers */}
                <Button onClick={sendDataToBackend} color="primary">
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Box>
      </Box>

      {isLoading ? (
        <div className="loader">
          <svg className="circular-loader" viewBox="25 25 50 50">
            <circle className="loader-path" cx="50" cy="50" r="20" />
          </svg>
        </div>
      ) : licenseList?.length <= 0 && !isLoading && page === 0 ? (
        <JumboListNoDataPlaceholder>
          <NoDataPlaceholder />
        </JumboListNoDataPlaceholder>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  padding: '0px !important',
                  boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                  borderRadius: '12px',
                  backgroundColor: ' rgb(255, 255, 255)',
                }}
              >
                <TableCell>LicenseId</TableCell>
                <TableCell>LicenseName</TableCell>
                <TableCell>AssignedLicense</TableCell>
                <TableCell>PurchasedQunatity</TableCell>
                <TableCell>BillingFrequency</TableCell>
                <TableCell>RenewsOn</TableCell>
                <TableCell>Plan</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {licenseList.map((contact, index) => (
                <LicenseCell
                  padding={'0px !important'}
                  contact={contact}
                  key={index}
                  getAllLicenses={getAllLicenses}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {licenseDialog.open && (
        <AddLicense
          open={licenseDialog.open}
          close={closeDialog}
          getAllLicenses={getAllLicenses}
          data={licenseDialog.data}
        />
      )}
    </React.Fragment>
  );
};

export default MasterAdminDashboard;
