import React from 'react';
import campaigns from 'modules/campaigns';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import JumboDdMenu from '@jumbo/components/JumboDdMenu/JumboDdMenu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GridWithHeader from 'app/CommonComponents/GridWithHeader';
import AddServer from './AddServer';
import AddTemplate from './AddTemplate';
import emailTemplate from 'modules/emailTemplate';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';

function EmailTemplates() {
  const Swal = useSwalWrapper();
  const [isLoading, setIsLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [emailTemplateList, setEmailTemplateList] = React.useState([]);
  const { showDialog, hideDialog } = useJumboDialog();
  const [serverDialog, setServerDialog] = React.useState({
    open: false,
  });
  const setOpenServerDialog = data => {
    setServerDialog({ open: true, data: data });
  };

  const handleCloseDialog = () => {
    setServerDialog({ open: false });
  };

  React.useEffect(() => {
    getAllEmailTemplates();
  }, []);
  const headers = {
    main: 'Email Templates',
    addButton: 'Add Email Template',
    columns: [
      { name: 'title', label: 'Title' },
      { name: 'type', label: 'Type' },
      { name: 'language', label: 'Language' },
      { name: 'subject', label: 'Subject' },
    ],
  };
  const getAllEmailTemplates = () => {
    emailTemplate.getAllEmailTemplates(response => {
      if (response.status === 'success') {
        setIsLoading(false);
        setEmailTemplateList(response.data);
      } else {
        setIsLoading(false);

        setEmailTemplateList([]);
      }
    });
  };

  const addEmailTemplate = data => {
    serverDialog.data
      ? emailTemplate.updateEmailTemplate(
          serverDialog.data.id,
          data,
          response => {
            if (response.status === 'success') {
              getAllEmailTemplates();

              Swal.fire({
                icon: 'success',
                title: 'Email Template has been updated successfully.',
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'There is an Error while updating email template.',
              });
            }
          }
        )
      : emailTemplate.addEmailTemplate(data, response => {
          if (response.status === 'success') {
            getAllEmailTemplates();

            Swal.fire({
              icon: 'success',
              title: 'Email Template has been added successfully.',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'There is an Error while adding email template.',
            });
          }
        });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllEmailTemplates();
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleItemAction = (actionType, item) => {
    switch (actionType.action) {
      case 'delete':
        showDialog({
          variant: 'confirm',
          title: 'Are you sure about deleting this Lesson?',
          content: "You won't be able to recover this Lesson later",
          onYes: () => handleDelete(item.id),
          onNo: hideDialog,
        });

        break;
      case 'edit':
        handleEdit(item);
        break;
    }
  };
  const handleDelete = id => {
    emailTemplate.deleteEmailTemplate(id, response => {
      if (response.status === 'success') {
        getAllEmailTemplates();
        Swal.fire({
          icon: 'success',
          title: 'Email Template has been deleted successfully.',
        });
        hideDialog();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while deleting email template.',
        });
      }
    });
  };
  const handleEdit = data => {
    setServerDialog({ ...serverDialog, open: true, data: data });
  };

  return (
    <>
      <GridWithHeader
        data={emailTemplateList}
        isLoading={isLoading}
        headers={headers}
        rowsPerPageOptions={[10]}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        actionsColumn={(item, index) => (
          <JumboDdMenu
            menuItems={menuItems}
            onClickCallback={actionType => handleItemAction(actionType, item)}
          />
        )}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        openDialog={() =>
          setServerDialog({ ...serverDialog, open: true, data: '' })
        }
      />
      {serverDialog.open ? (
        <AddTemplate
          open={serverDialog.open}
          close={handleCloseDialog}
          addEmailTemplate={addEmailTemplate}
        />
      ) : null}
      {serverDialog.data && serverDialog.open ? (
        <AddTemplate
          open={serverDialog.open}
          close={handleCloseDialog}
          addEmailTemplate={addEmailTemplate}
          edit={true}
          data={serverDialog.data}
        />
      ) : null}
    </>
  );
}

export default EmailTemplates;
const menuItems = [
  { icon: <EditIcon />, title: 'Edit', action: 'edit' },
  { icon: <DeleteIcon />, title: 'Delete', action: 'delete' },
];
