import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Slider,
  TextField,
  Typography,
} from '@mui/material';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import phishingCampaign from 'modules/phishingCampaign';

const ViewPhishingCampaign = ({
  phishing,
  getAllPhishingCampaigns,
  expanded,
  setExpanded,
}) => {
  const [selectedImage, setSelectedImage] = useState();
  const [enableAttachment, setEnableAttachment] = useState(false);
  const [prevData, setPrevData] = useState({});
  const Swal = useSwalWrapper();
  const validationSchema = yup.object().shape(
    {
      title: yup.string().when('title1', {
        is: title1 => !title1 || title1.length === 0,
        then: yup.string().required('At least one of the fields is required'),
      }),
      title1: yup.string().when('title', {
        is: title => !title || title.length === 0,
        then: yup.string().required('At least one of the fields is required'),
      }),
    },
    ['title', 'title1']
  );
  React.useEffect(() => {
    setEnableAttachment(phishing.enableAttachment);
  }, []);

  const editPhishingCampaign = data => {
    if (enableAttachment == true) {
      let formData = new FormData();
      formData.append('file', selectedImage);
      let data1 = { ...data, enableAttachment: true };
      formData.append('data', JSON.stringify(data1));
      phishingCampaign.updatePhishingCampaign(
        phishing.id,
        formData,
        response => {
          if (response.status === 'success') {
            setPrevData(response.data);
            getAllPhishingCampaigns();
            Swal.fire({
              icon: 'success',
              title: 'Phishing Campaign has been updated successfully.',
            });
          } else {
            console.log('error');
            Swal.fire({
              icon: 'error',
              title: 'There is an Error while updating Phishing Campaign.',
            });
          }
        }
      );
      return;
    }
    debugger;
    let formData = new FormData();
    formData.append('data', JSON.stringify(data));
    phishingCampaign.updatePhishingCampaign(phishing.id, formData, response => {
      if (response.status === 'success') {
        getAllPhishingCampaigns();
        setPrevData(response.data);
        Swal.fire({
          icon: 'success',
          title: 'Phishing Campaign has been updated successfully.',
        });
      } else {
        console.log('error');
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while updating Phishing Campaign.',
        });
      }
    });
  };
  console.log(prevData, 'fffff');
  const deletePhishingCampaign = () => {
    phishingCampaign.deletePhishingCampaign(phishing.id, response => {
      if (response.status === 'success') {
        setExpanded(false);
        getAllPhishingCampaigns();
        Swal.fire({
          icon: 'success',
          title: 'Phishing Campaign has been deleted successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while deleting Phishing Campaign.',
        });
      }
    });
  };
  const handleCheckboxChange = event => {
    setEnableAttachment(event.target.checked);
  };
  const handleImageFileinputChange = event => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };
  return (
    <div>
      <Formik
        validateOnChange={true}
        initialValues={{
          title: phishing?.title ? phishing?.title : '',
          title1: phishing?.title1 ? phishing?.title1 : '',
          page: phishing?.page ? phishing?.page : '',
          startDate: phishing?.startDate
            ? new Date(phishing.startDate).toISOString().split('T')[0]
            : new Date().toISOString().split('T')[0],
          dueDate: phishing?.dueDate
            ? new Date(phishing.dueDate).toISOString().split('T')[0]
            : new Date().toISOString().split('T')[0],
          enableAttachment: phishing?.enableAttachment
            ? phishing?.enableAttachment
            : false,
          attachmentName: phishing?.attachmentName
            ? phishing?.attachmentName
            : '',
          image: phishing?.image ? phishing?.image : '',
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={data => {
          data.image = phishing?.image ? phishing?.image : selectedImage?.name;
          editPhishingCampaign(data);
          console.log(data, 'dddd');
        }}
      >
        {({ values, setFieldValue }) => (
          <Form style={{ textAlign: 'left' }} noValidate autoComplete="off">
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={12} sm={6}>
                <JumboTextField fullWidth name="title" label="Title(English)" />
              </Grid>
              <Grid item xs={12} md={12} sm={6}>
                <JumboTextField
                  fullWidth
                  name="title1"
                  label="Title ( العربيه )"
                />
              </Grid>

              <Grid item xs={12} md={12} sm={6}>
                <JumboTextField
                  fullWidth
                  type="date"
                  name="startDate"
                  label="Start Date"
                />
              </Grid>
              <Grid item xs={12} md={12} sm={6}>
                <JumboTextField
                  fullWidth
                  type="date"
                  name="dueDate"
                  label="Due Date"
                  //   value={values.dueDate}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sm={6}
                sx={{ mb: 2, mt: 1, display: 'flex', alignItems: 'center' }}
              >
                <Checkbox
                  value="checkedA"
                  inputProps={{
                    'aria-label': 'Checkbox A',
                  }}
                  checked={enableAttachment}
                  onChange={handleCheckboxChange}
                />
                <p>Enable Attachment</p>
              </Grid>
            </Grid>
            {enableAttachment ? (
              <>
                <Grid item xs={12} md={12} sm={6} sx={{ mb: 2, mt: 1 }}>
                  <p>Attachment Name</p>
                  <JumboTextField
                    fullWidth
                    name="attachmentName"
                    label="Name"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} md={12} sm={6} sx={{ mb: 2, mt: 1 }}>
                  <p>Attachment</p>

                  <TextField
                    id="image"
                    name="image"
                    accept=".png,.svg, .jpg,.exe,.docx,.pdf"
                    type="file"
                    fullWidth
                    onChange={handleImageFileinputChange}
                  />
                </Grid>
              </>
            ) : (
              false
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',

                mt: 2,
              }}
            >
              <Button
                variant="contained"
                type="submit"
                sx={{ mr: 2 }}
                autoFocus
              >
                Update
              </Button>
              <Button
                variant="contained"
                onClick={deletePhishingCampaign}
                sx={{ mr: 2 }}
                autoFocus
              >
                Delete
              </Button>
              <Button
                variant="contained"
                onClick={() => setExpanded(!expanded)}
              >
                {' '}
                Cancel
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ViewPhishingCampaign;
